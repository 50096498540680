import React, { useEffect } from "react";
import { Button, Text } from "@evvve/ui-kit";
import style from "./ModalAlert.module.scss";

interface ModalAlertProps {
  onClose: () => void;
  isOpen: boolean;
  completed?: ModalAlertType | undefined;
}

export enum ModalAlertType {
  SUCCESS = "success",
  ERROR = "error",
}

export const ModalAlert = (props: ModalAlertProps) => {
  const { isOpen, onClose, completed } = props;

  const classname = completed && style[completed];

  useEffect(() => {
    setTimeout(onClose, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, completed]);

  return (
    <>
      {completed && isOpen ? (
        <div className={[classname, style.wrap].join(" ")}>
          <div />
          {completed === ModalAlertType.SUCCESS && (
            <Text size={["m", "m", 6, 6, 5, 5]} color="white" font="inter" bold>
              Message sent
            </Text>
          )}
          {completed === "error" && (
            <Text size={["m", "m", 6, 6, 5, 5]} color="white" font="inter" bold>
              Something went wrong. Please try again
            </Text>
          )}

          <div>
            <Button
              id="ok-allert-button"
              type="solid"
              theme="white"
              onClick={onClose}
              size="m"
            >
              OK
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};
