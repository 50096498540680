import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthType } from "../constants";

type Email = string | undefined | null;

export interface RegistrationMetadata {
  generatedUsername?: string;
  tempAccessToken?: string;
  firstName?: string;
  lastName?: string;
}

export interface Authorization {
  email: Email;
  stepForm: AuthType | null;
  isLoadingRequest: boolean;
  registrationMetadata: RegistrationMetadata | null;
}

const initialState: Authorization = {
  email: undefined,
  stepForm: AuthType.REGISTER_OR_LOGIN,
  isLoadingRequest: false,
  registrationMetadata: {},
};
const login = createSlice({
  name: "login",
  initialState,

  reducers: {
    setUserAuthEmail(state, action: PayloadAction<Email>) {
      state.email = action.payload;
    },
    setStepForm(state, action: PayloadAction<AuthType>) {
      state.stepForm = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoadingRequest = action.payload;
    },
    setRegistrationMetadata(
      state,
      action: PayloadAction<RegistrationMetadata | null>,
    ) {
      state.registrationMetadata = action.payload;
    },
  },
});
export const {
  setUserAuthEmail,
  setStepForm,
  setIsLoading,
  setRegistrationMetadata,
} = login.actions;

export default login.reducer;
