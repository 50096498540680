import React, { FC } from "react";
import { Text } from "@evvve/ui-kit";
import s from "./styles.module.scss";

type Props = {
  text: string;
};

const DateSeparator: FC<Props> = ({ text }) => (
  <div className={s.separator} id={text}>
    <Text size="xs" as="p" color="grayscale600" className={s.text}>
      {text}
    </Text>
  </div>
);

export default DateSeparator;
