import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React, { ReactElement } from "react";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../constants";

export interface Toast {
  type: string;
  content: React.ReactNode | ReactElement | null;
  isOpen: boolean;
  estimate: number;
  id: number;
}

export interface Toasts {
  toasts: Toast[];
}

const initialState: Toasts = {
  toasts: [],
};

const buildDefaultToast = (id: number, content: string): Toast => ({
  type: "default",
  content,
  estimate: DEFAULT_NOTIFICATION_ESTIMATE,
  id,
  isOpen: true,
});

const toastSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addChangesSavedToast(state) {
      state.toasts.push(buildDefaultToast(state.toasts.length + 1, "Changes saved"));
    },
    addErrorToast(state) {
      state.toasts.push(
        buildDefaultToast(state.toasts.length + 1, "Something went wrong"),
      );
    },
    addToastInvitationResult(state, action: PayloadAction<boolean>) {
      state.toasts.push(
        buildDefaultToast(
          state.toasts.length + 1,
          action.payload
            ? "Invitation sent"
            : "Invitation not sent. Please try again",
        ),
      );
    },
    addToast(state, action: PayloadAction<Omit<Toast, "isOpen" | "id">>) {
      state.toasts.push({
        ...action.payload,
        id: state.toasts.length + 1,
        isOpen: true,
      });
    },
    removeToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});
export const {
  addToast,
  removeToast,
  addErrorToast,
  addChangesSavedToast,
  addToastInvitationResult,
} = toastSlice.actions;

export default toastSlice.reducer;
