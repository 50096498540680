// FIX_ME:
/* eslint-disable array-callback-return */
import { SocialLinkLiteral, SocialMediaFormType, SocialMediaObject } from "./types";
import { CurrentUser, SocialLink } from "../../../../../../store/User/types";

export const socialLinksSerializer = (values: SocialMediaFormType) =>
  Object.keys(values)
    .filter((type) => values[type as SocialLinkLiteral].length > 0)
    .map((type) => ({
      type,
      url: values[type as SocialLinkLiteral],
    }));

export const parseUserSocialLinks = (user: CurrentUser | null) => {
  const obj: SocialMediaObject = {};
  // FIX_ME:
  // eslint-disable-next-line array-callback-return
  user?.social_links?.map((item: SocialLink) => {
    obj[item.type as keyof SocialMediaObject] = item.url;
  });

  return obj;
};
