/* eslint-disable no-shadow */
export enum ChatEventAction {
  NEW_DIALOG = "newDialog",
  NEW_MESSAGE = "receiveMessage",
  DELETE_DIALOG = "deleteDialog",
  CHAT_UPDATE = "chatUpdate",
  USER_UPDATE = "userUpdate",

  // emitter names
  SEND_MESSAGE = "sendMessage",
  LAST_READ_MESSAGE = "lastReadMessage",
  // NOTE: возможно не нужен, будет ясно после тестирования
  // CHOOSE_CHAT = "chooseChat",
  // NOTE: возможно не нужен, будет ясно после тестирования
  // ADD_ROOM = "addRoom",
}

export enum ServiceSocketEvents {
  CONNECT = "connect",
  ERROR = "error",
  DISCONNECT = "disconnect",
}
