// FIX_ME:
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { Nullable } from "src/shared/utils/utility-types";
import { messengerApi } from "../../api";

type Args = {
  chatId: string;
  lastEvaluatedKey: Nullable<unknown>;
};

export const updateChatListOnReadLastMsg = ({ chatId, lastEvaluatedKey }: Args) =>
  messengerApi.util.updateQueryData("getChatList", { lastEvaluatedKey }, (draft) => {
    draft.data.forEach((chat) => {
      if (chat.chat_id === chatId) {
        chat.unread_messages_count = 0;
      }
    });
  });
