import { useEffect, useState } from "react";

export const useTabletType = () => {
  const [isTabletOrDesktop, setIsTabletOrDesktop] = useState(
    window.matchMedia("(min-width: 768px)").matches,
  );
  const changeMediaWidth = () => {
    const tabletDetector = window.matchMedia("(min-width: 768px)").matches;
    setIsTabletOrDesktop(tabletDetector);
  };
  useEffect(() => {
    window.addEventListener("resize", changeMediaWidth, true);

    return () => {
      window.removeEventListener("resize", changeMediaWidth);
    };
  }, []);

  return isTabletOrDesktop;
};
