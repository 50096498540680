import { Icons, Text } from "@evvve/ui-kit";
import React, { FC, useMemo } from "react";
import cn from "classnames";
import { Nullable } from "src/shared/utils/utility-types";
import { ChatMessageDTO, MessageVariant } from "src/store/messenger";
import s from "./styles.module.scss";
import { systemMessagesLib } from "./fixtures";

type Props = {
  message: Nullable<ChatMessageDTO>;
};

const WARNING_COLOR = "tangerine1000";

const LastMessage: FC<Props> = ({ message }) => {
  const isWarning = message?.type === MessageVariant.SYSTEM;

  const lastMessageText = useMemo(() => {
    const systemMetadata = message?.system_metadata;

    const isSystemMessage =
      message?.type === MessageVariant.SYSTEM && systemMetadata;

    if (isSystemMessage) {
      return systemMessagesLib[systemMetadata.type];
    }

    return message?.text || null;
  }, [message?.system_metadata, message?.text, message?.type]);

  const textColor = isWarning ? WARNING_COLOR : "grayscale600";

  if (!message) return null;

  return (
    <div>
      <span
        className={cn({ [s.message_style]: !isWarning, [s.warning]: isWarning })}
      >
        {isWarning && <Icons.AlertCircle size="s" color={WARNING_COLOR} />}
        <Text key="lastMessage" size="xs" color={textColor}>
          {lastMessageText}
        </Text>
      </span>
    </div>
  );
};

export default LastMessage;
