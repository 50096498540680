// FIX_ME:
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-cycle */
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/dist/query/react";
import { BASE_API_URL } from "../constants";
// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { refreshTokens } from "../authorization/service/helpers";

const fetchFn = async (request: RequestInfo, options: RequestInit | undefined) => {
  const response = await fetch(request, options);

  if (response.status === 401) {
    refreshTokens(
      () => response,
      () => {
        const accessToken = localStorage.getItem("access-token");
        if (accessToken) {
          const retryOptions = {
            ...options,
            headers: {
              ...options?.headers,
              Authorization: `Bearer ${accessToken}`,
            },
          };

          return fetch(request, retryOptions);
        }
      },
    );
  }

  return response;
};

const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: `${BASE_API_URL}`,
      prepareHeaders: (headers) => {
        headers.set("Access-Control-Allow-Origin", "*");

        // FIX_ME:
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        localStorage.getItem("access-token") &&
          headers.set(
            "Authorization",
            `Bearer ${localStorage.getItem("access-token")}`,
          );

        return headers;
      },
      fetchFn,
    })(args, api, extraOptions);

    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    if (result.error?.status === 401) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    maxRetries: 1,
  },
);

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: [
    "UserEvents",
    "SessionList",
    "CurrentEvent",
    "SpeakerList",
    "UserProfile",
  ],
  endpoints: () => ({}),
});
