import React, { FC } from "react";
import { Text } from "@evvve/ui-kit";
import avatarSkeleton from "src/assets/images/avatar_skeleton.png";
import { useDesktopType } from "src/hooks";

import { CurrentUser } from "src/store/User/types";
import s from "./styles.module.scss";

type Props = {
  profileInfo: Omit<CurrentUser, "is_using_password">;
};

const ProfileHead: FC<Props> = ({ profileInfo }) => {
  const isDesktop = useDesktopType();

  return (
    <div className={s.head}>
      <img
        className={s.avatar}
        src={profileInfo?.avatar_url || avatarSkeleton}
        alt={`Avatar: ${profileInfo?.avatar_key}`}
      />
      <div className={s.head_info}>
        <div className={s.caption}>
          <Text size={["m", "m", "m", "m", "m", "m", 6]}>
            {profileInfo?.first_name}
            {!isDesktop ? "\n" : "\u0020"}
            {profileInfo?.last_name}
          </Text>
        </div>
        <div className={s.caption}>
          <Text size="s" color="violet600">
            {`@${profileInfo?.username}`}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ProfileHead;
