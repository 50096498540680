import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "../constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}`,
  prepareHeaders: (headers) => {
    headers.set("Access-Control-Allow-Origin", "*");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    localStorage.getItem("access-token") &&
      headers.set("Authorization", `Bearer ${localStorage.getItem("access-token")}`);

    return headers;
  },
});

export const apiPayment = createApi({
  reducerPath: "apiPayment",
  baseQuery,
  endpoints: () => ({}),
});
