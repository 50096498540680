// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
import { Avatar, Text } from "@evvve/ui-kit";
import style from "./style.module.scss";

const { user_picture, user_name } = style;

export interface UserSummaryProps {
  avatar: string;
  name: string;
  username?: string;
  className?: string;
  isCurrentUser?: boolean;
  company?: string;
  theme?: "dark" | "light";
}

const UserSummary = ({
  avatar,
  name,
  username,
  company,
  className,
  isCurrentUser,
  theme = "dark",
}: UserSummaryProps) => (
  <div className={`${style.userSummary} ${className}`}>
    <Avatar status="offline" size="s" src={avatar} alt="" className={user_picture} />
    <div className={user_name}>
      <Text
        color={theme === "dark" ? "grayscale1000" : "white"}
        size="m"
        className={style.oneRow}
      >
        {name}&nbsp;
        {isCurrentUser && (
          <Text as="span" size="m" bold>
            (Me)
          </Text>
        )}
      </Text>
      {company && (
        <Text
          color={theme === "dark" ? "grayscale600" : "grayscale300"}
          size="xs"
          className={style.oneRow}
        >
          {company}
        </Text>
      )}
      {username && (
        <Text color="grayscale600" size="xs" className={style.oneRow}>
          {username}
        </Text>
      )}
    </div>
  </div>
);
export default UserSummary;
