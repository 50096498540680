/* eslint-disable implicit-arrow-linebreak */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventResponse, EventType } from "./types";
import { timezoneItems } from "../../close-part/features/EventBasicSettings/data";

export const getTzOffset = (tzName: string) =>
  timezoneItems.find((tz) => tz.value === tzName)?.data.offset || 0;

interface EventSlice {
  // We cannot use Moments in the state due to the error: "A non-serializable value was detected in the state"
  event: EventResponse | null;
  eventType: EventType | null;
  eventPrice: number;
  eventTzOffset?: number;
  isPaymentDisabled: boolean;
}

const initialState: EventSlice = {
  event: null,
  eventType: null,
  eventPrice: 0,
  eventTzOffset: undefined,
  isPaymentDisabled: false,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEvent(state, { payload }: PayloadAction<EventResponse>) {
      state.event = payload;
      state.eventTzOffset = getTzOffset(payload.tz);
    },
    setEventPrice(state, action: PayloadAction<number>) {
      state.eventPrice = action.payload;
    },
    setIsPaymentDisabled(state, action: PayloadAction<boolean>) {
      state.isPaymentDisabled = action.payload;
    },
    setEventTzOffset(state, action: PayloadAction<number | string>) {
      state.eventTzOffset =
        typeof action.payload === "string"
          ? getTzOffset(action.payload)
          : action.payload;
    },
    clearEventState(state) {
      state.event = null;
      state.eventType = null;
      state.eventPrice = 0;
      state.eventTzOffset = undefined;
    },
    setEventType(state, action: PayloadAction<EventType>) {
      state.eventType = action.payload;
    },
  },
});

export const {
  setEvent,
  setEventPrice,
  setIsPaymentDisabled,
  clearEventState,
  setEventTzOffset,
  setEventType,
} = eventSlice.actions;

export default eventSlice.reducer;
