import { useSearchParams } from "react-router-dom";
import { MessengerViewVariant } from "../types";

enum ParamKeyVariant {
  CHAT_ID = "chatId",
  CHAT_BY_USER_ID = "chatUserId",
  IS_PATICIPANTS_VIEW = "isPaticipantsView",
  PROFILE_ID = "profileId",
}

type MessengerViewParams = {
  chatId?: string;
  profileId?: string;
  userId?: string;
};

const paramKeyList = Object.values(ParamKeyVariant).map((paramKey) => paramKey);

export const useMessengerViewController = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // NOTE: existed params values --- START ----
  const chatIdParam = searchParams.get(ParamKeyVariant.CHAT_ID);
  const chatByUserParam = searchParams.get(ParamKeyVariant.CHAT_BY_USER_ID);
  const isParticipantsViewParam = searchParams.get(
    ParamKeyVariant.IS_PATICIPANTS_VIEW,
  );
  const profileIdParam = searchParams.get(ParamKeyVariant.PROFILE_ID);
  // NOTE: existed params values --- END ----

  const getCurrentView = () => {
    const isChatIdExist = chatIdParam || chatByUserParam;

    if (isChatIdExist && isParticipantsViewParam) {
      return MessengerViewVariant.CHAT_PARTICIPANTS;
    }

    if (profileIdParam) {
      return MessengerViewVariant.PROFILE;
    }

    if (chatIdParam) return MessengerViewVariant.CHAT_BY_ID;
    if (chatByUserParam) return MessengerViewVariant.CHAT_BY_USER;

    return MessengerViewVariant.CHAT_LIST;
  };

  const currentChatVariant =
    getCurrentView() === MessengerViewVariant.CHAT_BY_USER
      ? ParamKeyVariant.CHAT_BY_USER_ID
      : ParamKeyVariant.CHAT_ID;

  // TODO: вынести в общие хелперы?
  const excludeIntersections = (
    allKeys: ParamKeyVariant[],
    keysToSkip: ParamKeyVariant[],
  ) => allKeys.filter((paramKey) => !keysToSkip.includes(paramKey));

  const deleteAllParams = () => {
    paramKeyList.forEach((paramKey) => {
      searchParams.delete(paramKey);
    });
    setSearchParams(searchParams, { replace: true });
  };

  const refreshSearchParam = (paramKey: ParamKeyVariant, value?: string) => {
    if (!value) throw Error("CHAT: There are not value of query param");

    searchParams.set(paramKey, value);
  };

  const deleteParams = (paramKeys: ParamKeyVariant[]) => {
    paramKeys.forEach((param) => {
      searchParams.delete(param);
    });

    setSearchParams(searchParams, { replace: true });
  };

  const onChangeLocation = (
    key: ParamKeyVariant,
    value: string,
    paramKeysToSkip: ParamKeyVariant[] = [],
  ) => {
    refreshSearchParam(key, value);

    const paramsToDelete = excludeIntersections(paramKeyList, [
      key,
      ...paramKeysToSkip,
    ]);

    deleteParams(paramsToDelete);
  };

  const goToChatByIdView = (chatId: string) => {
    onChangeLocation(ParamKeyVariant.CHAT_ID, chatId);
  };

  const goToChatByUserView = (userId: string) => {
    onChangeLocation(ParamKeyVariant.CHAT_BY_USER_ID, userId);
  };

  const goToProfileView = (profileId: string) => {
    onChangeLocation(ParamKeyVariant.PROFILE_ID, profileId, [currentChatVariant]);
  };

  const goToParticipantsView = (contextMenuChatId?: string) => {
    if (contextMenuChatId)
      refreshSearchParam(ParamKeyVariant.CHAT_ID, contextMenuChatId);
    onChangeLocation(ParamKeyVariant.IS_PATICIPANTS_VIEW, "true", [
      currentChatVariant,
    ]);
  };

  const goToChatListView = () => {
    // NOTE: Переходя к списку чатов - удаляем все параметры из поисковой строки
    deleteAllParams();
  };

  const goToView = (
    messengerView: MessengerViewVariant,
    { chatId, userId, profileId }: MessengerViewParams | void = {},
  ) => {
    const isChatByIdView = messengerView === MessengerViewVariant.CHAT_BY_ID;
    const isChatByUserView = messengerView === MessengerViewVariant.CHAT_BY_USER;

    if ((isChatByIdView && !chatId) || (isChatByUserView && !userId)) {
      const chatIdParamValue = searchParams.get(ParamKeyVariant.CHAT_ID);
      const chatByUserParamValue = searchParams.get(ParamKeyVariant.CHAT_BY_USER_ID);

      if (!chatIdParamValue && !chatByUserParamValue) goToChatListView();

      if (chatIdParamValue) goToChatByIdView(chatIdParamValue);
      if (chatByUserParamValue) goToChatByUserView(chatByUserParamValue);
    }

    if (isChatByIdView) {
      if (!chatId) throw new Error("[CHAT]: there is no chatId");
      goToChatByIdView(chatId);
    }

    if (isChatByUserView) {
      if (!userId) throw new Error("[CHAT]: there is no userId");
      goToChatByUserView(userId);
    }

    if (messengerView === MessengerViewVariant.CHAT_LIST) {
      goToChatListView();
    }

    if (messengerView === MessengerViewVariant.PROFILE) {
      if (!profileId) throw new Error("[CHAT]: there is no profileId");
      goToProfileView(profileId);
    }

    if (messengerView === MessengerViewVariant.CHAT_PARTICIPANTS) {
      goToParticipantsView();
    }
  };

  return {
    chatId: chatIdParam,
    userId: chatByUserParam,
    profileId: profileIdParam,
    currentView: getCurrentView(),
    goToChatList: goToChatListView,
    goToParticipants: goToParticipantsView,
    goToView,
  };
};
