// TODO: некоторые компоненты этого модуля доподлинно копируют друг друга
// необходимо выделить полностью повторяющиеся комопненты и объединить их в абстракцию
// отдельно проанализировать оставшиеся уникальные элементы и попробовать абстрагировать и их
import React, { FC } from "react";
import { ChatMessageDTO, SystemMessageVariant } from "src/store/messenger";
import {
  EventDetailsHaveChanged,
  EventDurationExceeded,
  EventHasBeenCancelled,
  EventIsLive,
  EventStartsIn15Min,
  EventStartsIn3Hours,
  IAcceptedToBeSpeaker,
  IAmOrganizingEvent,
  IAmSpeaker,
  IWillNotBeSpeaker,
  InvitationToGoLive,
  NewSpeakerAtEvent,
  WelcomeToEvent,
  WelcomeToPlatform,
  YouInvitedAsSpeaker,
} from "./ui";

type Props = {
  message: ChatMessageDTO;
};

const SystemMessageItem: FC<Props> = ({ message }) => {
  const systemMetadata = message.system_metadata;

  switch (systemMetadata?.type) {
    case SystemMessageVariant.EVENT_DETAILS_HAVE_CHANGED:
      return <EventDetailsHaveChanged metadata={systemMetadata} />;
    case SystemMessageVariant.EVENT_HAS_BEEN_CANCELLED:
      return <EventHasBeenCancelled metadata={systemMetadata} />;
    case SystemMessageVariant.EVENT_DURATION_EXCEEDED:
      return <EventDurationExceeded />;
    case SystemMessageVariant.EVENT_IS_LIVE:
      return <EventIsLive metadata={systemMetadata} />;
    case SystemMessageVariant.EVENT_STARTS_IN_15_MIN:
      return <EventStartsIn15Min metadata={systemMetadata} />;
    case SystemMessageVariant.EVENT_STARTS_IN_3_HOURS:
      return <EventStartsIn3Hours metadata={systemMetadata} />;
    case SystemMessageVariant.INVITATION_TO_GO_LIVE:
      return <InvitationToGoLive metadata={systemMetadata} />;
    case SystemMessageVariant.I_ACCEPTED_TO_BE_SPEAKER_AT_EVENT:
      return <IAcceptedToBeSpeaker metadata={systemMetadata} />;
    case SystemMessageVariant.I_AM_ORGANIZING_EVENT:
      return <IAmOrganizingEvent metadata={systemMetadata} />;
    case SystemMessageVariant.I_AM_SPEAKER_AT_EVENT:
      return <IAmSpeaker metadata={systemMetadata} />;
    case SystemMessageVariant.I_WILL_NOT_BE_SPEAKER_AT_EVENT:
      return <IWillNotBeSpeaker metadata={systemMetadata} />;
    case SystemMessageVariant.NEW_SPEAKER_AT_EVENT:
      return <NewSpeakerAtEvent metadata={systemMetadata} />;
    case SystemMessageVariant.WELCOME_TO_EVENT:
      return <WelcomeToEvent metadata={systemMetadata} text={message.text} />;
    case SystemMessageVariant.WELCOME_TO_PLATFORM:
      return <WelcomeToPlatform />;
    case SystemMessageVariant.YOU_HAVE_BEEN_INVITED_TO_BE_SPEAKER:
      return <YouInvitedAsSpeaker metadata={systemMetadata} />;
    default:
      return null;
  }
};

export default SystemMessageItem;
