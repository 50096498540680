import React, { FC } from "react";
import cn from "classnames";
import s from "./styles.module.scss";

type Props = {
  width?: number | string;
  height?: number | string;
  isRounded?: boolean;
};

const Placeholder: FC<Props> = ({ width, height, isRounded }) => (
  <div
    className={cn(s.placeholder, { [s.isRounded]: isRounded })}
    style={{ width, height }}
  />
);

export default Placeholder;
