import { ChatMessageDTOExtended } from "src/store/messenger";

const getDateString = (datetime?: Date | string) => {
  const fullDate = datetime ? new Date(datetime) : new Date();

  const date = fullDate.getDate();
  const month = fullDate.toLocaleString("default", {
    month: "long",
  });

  return `${month} ${date}`;
};

const getSeparator = (messageDateString: string, isUnread?: boolean) => {
  if (isUnread) return "Unread messages";

  const todayDateString = getDateString();
  const isTodayMessage = todayDateString === messageDateString;

  return isTodayMessage ? "Today" : messageDateString;
};

export const getMessagesWithDateDividers = (
  messageList: ChatMessageDTOExtended[],
) => {
  const list: (ChatMessageDTOExtended | string)[] = [];

  messageList.forEach((currentMessage, index) => {
    const prevMessage = messageList[index - 1];

    const messageDateString = getDateString(currentMessage.datetime);
    const dateStringHumanized = getSeparator(messageDateString);

    // NOTE: previousMessage
    const prevMessageDateString = getDateString(prevMessage?.datetime);
    const prevDateStringHumanized = getSeparator(prevMessageDateString);

    const shouldAddSeparator =
      !prevMessageDateString || dateStringHumanized !== prevDateStringHumanized;

    const isPrevMessageRead = !prevMessage?.isUnread;
    const isCurrentMessageUnread = currentMessage.isUnread;

    const shouldAddUnreadSeparator = isPrevMessageRead && isCurrentMessageUnread;

    if (shouldAddUnreadSeparator) list.push("Unread messages");
    if (shouldAddSeparator) list.push(dateStringHumanized);

    list.push(currentMessage);
  });

  return list;
};
