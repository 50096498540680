import { EventType } from "../../store/events/types";

export interface StreamingDataRequest {
  eventId: string;
}

// any role except organizer
export interface LiveHlsDataResponse {
  hls_url: string;
  is_paused: boolean;
  broadcast_resources_status: string;
  history_url: string;
}
export interface LiveHlsDataResponseMobile {
  hls_url: string;
  hls_rez_map: UrlByQualityDictionary;
}

export type UrlByQualityDictionary = { [quality: string]: string }; // {‘1080p’: ‘url’, ‘720p’: ….}

// organizer
export interface StreamingDataResponse {
  endpoint?: string;
  key?: string;
  broadcast_resources_status: string;
}

export interface Device {
  deviceId: string;
  label: string;
}

export enum StreamingAction {
  // simple
  GO_TO_LIVE = "go_to_live",
  START_EVENT = "start_event",
  END_EVENT = "end_event",
  RESUME_STREAM = "resume_stream",
  PAUSE_STREAM = "pause_stream",
  END_BROADCAST = "end_broadcast",

  // complex
  // START_SESSION = "start_session",
  END_SESSION = "end_session",
  INVITE_SPEAKER = "invite_speaker",
  ACCEPT_INVITATION_AS_SPEAKER = "accept_invitation_as_speaker",
}

export interface StreamingActionPayload {
  event_id: string;
  action: StreamingAction;
  extra: {
    event_type?: EventType;
    is_org?: boolean;
    session_id?: string;
    speaker_id?: string;
  };
}

// simple event
export enum LiveStreamStatus {
  BEFORE_EVENT = "BEFORE_EVENT", // event.status is UPCOMING
  IVS_RESOURCES_INIT = "IVS_RESOURCES_INIT", // click on "Start event", event.status is PRE_LIVE
  PRE_BEFORE_STREAM = "PRE_BEFORE_STREAM", // click on "Start event", event.status is LIVE
  BEFORE_STREAM = "BEFORE_STREAM", // event.status is LIVE
  CHECK_DEVICES = "CHECK_DEVICES",
  // PRE_STREAM = "PRE_STREAM",
  STREAM = "STREAM",
  PRE_PAUSE = "PRE_PAUSE",
  PAUSE = "PAUSE",
  PRE_RESUME = "PRE_RESUME", // click on "Resume" to check devices and come back to STREAM
  RESUME = "RESUME", // click on "Resume" and come back to STREAM
  PRE_AFTER_STREAM = "PRE_AFTER_STREAM", // click on "End broadcast"
  AFTER_STREAM = "AFTER_STREAM", // broadcast is finished, event.status is still LIVE
  PRE_AFTER_EVENT = "PRE_AFTER_EVENT", // event.status is PASSED
  AFTER_EVENT = "AFTER_EVENT", // event.status is PASSED
  PRE_AFTER_STREAM_AND_EVENT = "PRE_AFTER_STREAM_AND_EVENT", // "end event" before ending the broadcast
  TECH_ISSUES = "TECH_ISSUES",
  //
  // COMPLEX
  //
  PRE_START_SESSION = "PRE_START_SESSION",
  START_SESSION = "START_SESSION",
  PRE_WAITING_FOR_SPEAKER = "PRE_WAITING_FOR_SPEAKER",
  WAITING_FOR_SPEAKER = "WAITING_FOR_SPEAKER",
  PRE_END_SESSION = "PRE_END_SESSION",
}

export const fitEventLiveViewMetricLssList = [
  LiveStreamStatus.BEFORE_STREAM,
  LiveStreamStatus.CHECK_DEVICES,
  LiveStreamStatus.STREAM,
  LiveStreamStatus.PRE_PAUSE,
  LiveStreamStatus.PAUSE,
  LiveStreamStatus.PRE_RESUME,
  LiveStreamStatus.RESUME,
  LiveStreamStatus.PRE_AFTER_STREAM,
  LiveStreamStatus.TECH_ISSUES,
];
