import { messengerApi } from "src/store/messenger";

import { Nullable } from "src/shared/utils";

export const usePullMessageList = (chatId: Nullable<string>) => {
  const [
    getInitialPartOfMessagesQuery,
    {
      data: messageListData,
      isLoading: initialPartIsLoading,
      isUninitialized: initialPartIsUninitialized,
    },
  ] = messengerApi.useLazyGetMessagesHistoryQuery();

  // NOTE: временно закомменировано пока идет работа над чатом
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [
    getNextPartOfMessagesQuery,
    { isLoading: nextPartIsLoading, isFetching: nextPartIsFetching },
  ] = messengerApi.useLazyGetMessageListQuery();

  const { data: messageList = [], lastEvaluatedKey } = messageListData || {};

  const onLoadMoreMessages = () => {
    if (!messageList || !chatId) throw new Error("TODO: обработать");

    const initialLastEvaluatedKey = lastEvaluatedKey;

    if (initialLastEvaluatedKey) {
      // Нужно запрашивать только в случае если есть lastEvKey

      return getNextPartOfMessagesQuery({
        chatId,
        lastEvaluatedKey: initialLastEvaluatedKey,
      });
    }

    return Promise.reject();
  };

  const getInitialMessageList = () => {
    if (!chatId) throw new Error("[MESSENGER]: There is no chatId");

    getInitialPartOfMessagesQuery({ chatId });
  };

  return {
    nextPartIsLoading: nextPartIsLoading || nextPartIsFetching,
    initialPartIsLoading,
    getInitialMessageList,
    messageListData,
    messageList: messageListData?.data || [],
    initialPartIsUninitialized,
    lastEvaluatedKey: messageListData?.lastEvaluatedKey,
    onLoadMoreMessages,
  };
};
