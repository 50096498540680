import { SelectItemType } from "@evvve/ui-kit/dist/components/Select/SelectItemType";
import {
  EventCategory,
  EventRegistrationType,
  EventType,
  TimeZoneSelectItemType,
} from "src/store/events/types";
import { RadioButtonProps } from "@evvve/ui-kit/dist/components/RadioButton";
import timezones from "timezones.json";
import { capitalizeFirstLetter } from "../../../helpers/text";

// SelectItemType, RadioButtonProps
export interface ItemType {
  label: string;
  value: string;
}
const buildItemForEnumItem = (value: string): ItemType => ({
  label: capitalizeFirstLetter(value),
  value,
});

export const categories: SelectItemType[] = [
  {
    label: "Choose event category",
    value: "",
  },
  buildItemForEnumItem(EventCategory.CULTURE),
  buildItemForEnumItem(EventCategory.SCIENCE),
  buildItemForEnumItem(EventCategory.EDUCATION),
  buildItemForEnumItem(EventCategory.BUSINESS),
  buildItemForEnumItem(EventCategory.SOCIETY),
  buildItemForEnumItem(EventCategory.SPORT),
  buildItemForEnumItem(EventCategory.LIFESTYLE),
  buildItemForEnumItem(EventCategory.OTHER),
];

const userAgentTzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const timezoneItems: TimeZoneSelectItemType[] = timezones.map((tz) => ({
  label: tz.text,
  value: tz.value,
  data: {
    offset: tz.offset,
    isUserAgentTz: tz.utc.some((name) => name === userAgentTzName),
  },
}));

export const getTimeZoneSelectItem = (tz: string) =>
  timezoneItems.find((item) => item.value === tz) || timezoneItems[0];

export const registrationTypes: RadioButtonProps[] = [
  buildItemForEnumItem(EventRegistrationType.PUBLIC),
  buildItemForEnumItem(EventRegistrationType.PRIVATE),
];

export const eventTypes: RadioButtonProps[] = [
  buildItemForEnumItem(EventType.SIMPLE),
  buildItemForEnumItem(EventType.COMPLEX),
];
