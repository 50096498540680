// FIX_ME:
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import { Button, Input, Text, VisibilityButton } from "@evvve/ui-kit";
import { useModal } from "src/hooks";
import { Formik, FormikHelpers } from "formik";
import { FormikProps } from "formik/dist/types";
import { useGetCurrentUserQuery, useSetPasswordMutation } from "src/store/User/api";
import { ApiError, parseErrorMessage } from "src/helpers/apiHelpers";
import { addToast } from "src/store/toast/slice";
import { useAppDispatch } from "src/hooks/useStoreHooks";
import * as Yup from "yup";
import {
  validateNewPassword,
  validateNewPasswordRepeat,
} from "src/helpers/validation";
import { useValidationByInput } from "src/hooks/useValidationByInput";
import { PasswordRequirements } from "./PasswordRequirements";
import style from "./style.module.scss";
import ModalTemplate from "../ModalTemplate";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../../../constants";
import { setUserInfo } from "../../../../store/User/slice";

const { title, info, inputs, submit } = style;

interface SetPasswordForm {
  newPassword: string;
  copyPassword: string;
}

interface State {
  showPassword: boolean;
  showPasswordCopy: boolean;
}

const initialState: State = {
  showPassword: false,
  showPasswordCopy: false,
};

const SetPasswordModal = () => {
  const { openModal, isOpenModal, closeModal } = useModal();
  const [setUserPassword, { isLoading }] = useSetPasswordMutation();
  const { refetch: userRefetch } = useGetCurrentUserQuery();
  const [isVisible, setIsVisible] = useState<State>(initialState);
  const dispatch = useAppDispatch();

  const resetPasswordVisibilityState = () => {
    setIsVisible(initialState);
  };

  const Form = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldError,
  }: FormikProps<SetPasswordForm>) => {
    const isSubmitDisabled = useValidationByInput(touched, errors);
    const resetErrorAndChange = (
      field: string,
      e: React.ChangeEvent | React.FormEvent,
    ) => {
      setFieldError(field, "");
      handleChange(e);
    };

    return (
      <form onBlur={handleBlur} onSubmit={(e) => handleSubmit(e)}>
        <div className={inputs}>
          <Input
            label="New password"
            name="newPassword"
            value={values?.newPassword || ""}
            placeholder="Enter new password"
            onChange={(e) => resetErrorAndChange("newPassword", e)}
            type={isVisible.showPassword ? "text" : "password"}
            error={touched.newPassword && errors.newPassword}
            endAdornment={
              <VisibilityButton
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    showPassword: !isVisible.showPassword,
                  })
                }
                showPassword={isVisible.showPassword}
              />
            }
          />
          <Input
            label="Repeat new password"
            name="copyPassword"
            value={values?.copyPassword || ""}
            placeholder="Repeat new password"
            onChange={(e) => resetErrorAndChange("copyPassword", e)}
            type={isVisible.showPasswordCopy ? "text" : "password"}
            error={touched.copyPassword && errors.copyPassword}
            endAdornment={
              <VisibilityButton
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    showPasswordCopy: !isVisible.showPasswordCopy,
                  })
                }
                showPassword={isVisible.showPasswordCopy}
              />
            }
          />
        </div>
        <div className={submit}>
          <Button
            theme="violet"
            size="l"
            type="solid"
            disabled={isLoading || isSubmitDisabled}
            htmlType="submit"
            isLoading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    );
  };

  const validationSchema = Yup.object().shape({
    newPassword: validateNewPassword(),
    copyPassword: validateNewPasswordRepeat("newPassword"),
  });

  return (
    <ModalTemplate
      isOpen={isOpenModal}
      onClose={() => {
        closeModal();
        resetPasswordVisibilityState();
      }}
      OpenElement={
        <Button
          size="m"
          theme="violet"
          type="outline"
          onClick={() => {
            openModal();
          }}
        >
          Set password
        </Button>
      }
    >
      <div className={title}>
        <Text size={[6, 6, 4]} bold>
          Set password
        </Text>
      </div>
      <div className={info}>
        <Text size="m">
          You can set a password to log in without a temporary code.
        </Text>
      </div>
      <PasswordRequirements />
      <Formik<SetPasswordForm>
        initialValues={{ newPassword: "", copyPassword: "" }}
        validateOnBlur
        validateOnChange={false}
        onSubmit={async (values, formikHelpers: FormikHelpers<SetPasswordForm>) => {
          try {
            const requestValue = {
              password: values.newPassword,
            };
            await setUserPassword(requestValue).unwrap();
            const response = await userRefetch().unwrap();
            dispatch(setUserInfo(response));
            closeModal();
            formikHelpers.resetForm();
            resetPasswordVisibilityState();
            dispatch(
              addToast({
                type: "default",
                content: "Password set",
                estimate: DEFAULT_NOTIFICATION_ESTIMATE,
              }),
            );
          } catch (error) {
            if (!(error as ApiError).data) throw error;
            const errorData = parseErrorMessage(error as ApiError);
            alert(errorData.message);
          }
        }}
        validationSchema={validationSchema}
        component={Form}
      />
    </ModalTemplate>
  );
};
export default SetPasswordModal;
