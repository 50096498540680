import React, { FC } from "react";

import { Icons, Text } from "@evvve/ui-kit";

import Link from "src/shared-features/Link";
import { changeDateFormat } from "src/helpers/date";

import cn from "classnames";
import { IAcceptedToBeASpeakerAtTheEventMetadata } from "src/store/messenger";
import systemNotificationsStyles from "../style.module.scss";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const { linkText } = systemNotificationsStyles;

type Props = {
  metadata: IAcceptedToBeASpeakerAtTheEventMetadata;
};

const IAcceptedToBeSpeaker: FC<Props> = ({ metadata }) => {
  const {
    event_id: eventId,
    event_date: eventDate,
    event_name: eventTitle,
  } = metadata.data;

  return (
    <NotificationTemplate>
      <div className={styles.info}>
        <Icons.Info color="tangerine1000" size="s" />
        <Text size="m" color="tangerine1000">
          I accepted to be a speaker at the event
        </Text>
      </div>
      <Link
        className={cn(styles.eventTitle, linkText)}
        size="m"
        color="violet"
        to={`/catalog/event/${eventId}`}
        underline={false}
        hoverUnderLine={false}
      >
        {eventTitle}
      </Link>
      <div className={styles.dataPriceContainer}>
        <div className={styles.section}>
          <Text
            className={styles.dateInscription}
            size="xs"
            color="grayscale800"
            as="p"
          >
            Date
          </Text>
          <Text size="xs" color="grayscale800" as="p">
            {changeDateFormat(eventDate)}
          </Text>
        </div>
      </div>
    </NotificationTemplate>
  );
};

export default IAcceptedToBeSpeaker;
