// FIX_ME:
/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import { useGetCurrentUserQuery } from "../../store/User/api";
import { setResponsiveLayoutConfig, setUserInfo } from "../../store/User/slice";
import { LoaderWrap } from "../LoaderWrap/LoaderWrap";
import {
  refreshTokens,
  validateAccessToken,
} from "../../authorization/service/helpers";
import { useResponsiveLayout } from "../../hooks/useResponsiveLayout";

export const AppWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isSkip, setIsSkip] = useState(false);

  const userInfo = useAppSelector((state) => state.user.userInfo);

  const [isRefreshingTokens, setIsRefreshingTokens] = useState(false);

  const responsiveLayout = useResponsiveLayout();
  useEffect(() => {
    if (
      responsiveLayout &&
      (responsiveLayout.isDesktop ||
        responsiveLayout.isTablet ||
        responsiveLayout.isMobile)
    ) {
      dispatch(setResponsiveLayoutConfig(responsiveLayout));
    }
  }, [responsiveLayout, dispatch]);

  const {
    data: currentUser,
    isLoading,
    isSuccess,
  } = useGetCurrentUserQuery(undefined, { skip: isSkip });

  useEffect(() => {
    if (isLoading) return;
    setIsSkip(true);
    if (isSuccess && currentUser) dispatch(setUserInfo(currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const token = localStorage.getItem("access-token");
    if (validateAccessToken(token)) return;

    const refreshToken = localStorage.getItem("refresh-token");
    if (!userInfo || !refreshToken || !validateAccessToken(refreshToken)) {
      // Init is used both in open and closed part
      // When refresh token is expired, init returns 404, auto refresh-tokens returns 401
      // Open part should be available for everyone => remove tokens
      // => get event data instead of 404 + user auto logout
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");

      return;
    }

    setIsRefreshingTokens(true);
    refreshTokens(
      () => {
        dispatch(setUserInfo(null));
        setIsRefreshingTokens(false);
      },
      () => setIsRefreshingTokens(false),
    );
  });

  if (isLoading || isRefreshingTokens) return <LoaderWrap />;

  return <>{children}</>;
};
