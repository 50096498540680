import { Icons } from "@evvve/ui-kit";
import React, { FC } from "react";
import { ChatDTO, ChatVariant } from "src/store/messenger";

import s from "./styles.module.scss";

const ICON_COLOR = "grayscale600";

type Props = {
  chatType: ChatVariant;
  isSystem: ChatDTO["system"];
};

const ChatIcon: FC<Props> = ({ chatType, isSystem }) => {
  const getIconByChatType = () => {
    switch (chatType) {
      case ChatVariant.GROUP:
        return <Icons.Users color={ICON_COLOR} />;

      case ChatVariant.PRIVATE:
        return isSystem ? <Icons.Star color={ICON_COLOR} /> : null;

      default:
        return null;
    }
  };

  const chatIcon = getIconByChatType();

  return chatIcon ? <div className={s.iconWrapper}>{chatIcon}</div> : null;
};

export default ChatIcon;
