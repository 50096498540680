import React, { FC } from "react";

import { Icons, Text } from "@evvve/ui-kit";
import { ChatVariant } from "src/store/messenger";
import s from "./styles.module.scss";

const GROUP_CHAT_PLACEHOLDER_TEXT =
  "This is where all event\n communication magic will\n happen. Be the first to\n greet future participants!";
const PERSONAL_CHAT_PLACEHOLDER_TEXT =
  "You haven’t chatted yet.\n Send a message to start\n networking";

type Props = {
  chatVariant?: ChatVariant;
};

const MessageListPlaceholder: FC<Props> = ({ chatVariant }) => {
  const isGroupChat = chatVariant === ChatVariant.GROUP;

  const text = isGroupChat
    ? GROUP_CHAT_PLACEHOLDER_TEXT
    : PERSONAL_CHAT_PLACEHOLDER_TEXT;

  return (
    <div className={s.noYetMessagesWrap}>
      <Icons.Smile size="m" color="grayscale600" />
      <Text size="m" className={s.text}>
        {text}
      </Text>
    </div>
  );
};

export default MessageListPlaceholder;
