// FIX_ME:
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import eventSlice from "src/store/events/eventSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { chatApi } from "src/api/chatApi";
import broadcastSlice from "src/broadcasting/service/slice";
import { apiPayment } from "src/api/apiPayment";
import AuthSlice from "../authorization/service/slice";
import { rtkQueryErrorHandler } from "./middleware/rtkQueryErrorHandler";
import { appApi } from "../api/api";
import userSlice from "./User/slice";
import toastSlice from "./toast/slice";
import otherUserSlice from "./otherUser/otherUserSlice";

const combinedReducer = combineReducers({
  login: AuthSlice,
  user: userSlice,
  otherUser: otherUserSlice,
  toast: toastSlice,
  event: eventSlice,
  broadcast: broadcastSlice,
  [appApi.reducerPath]: appApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [apiPayment.reducerPath]: apiPayment.reducer,
});

// FIX_ME:
// @ts-ignore
const rootReducer = (state, action: AnyAction) => {
  if (action.type === "RESET") {
    state = {};
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(appApi.middleware)
      .concat(chatApi.middleware)
      .concat(apiPayment.middleware)
      .concat(rtkQueryErrorHandler),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export interface PageResponse<T> {
  items: T[];
  total: number;
  page: number;
  pages: number;
  size: number;
}
