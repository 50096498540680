import moment from "moment";
import { CurrentUser } from "src/store/User/types";
import {
  ChatMessageDTOExtended,
  MessageSendingStatus,
  MessageVariant,
  SendNewMessageData,
} from "../types";

interface Args extends SendNewMessageData {
  currentUser: CurrentUser;
}

export const generateNewMessage = ({
  currentUser,
  ...body
}: Args): ChatMessageDTOExtended => ({
  chat_id: body.chat_id,
  datetime: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  id: body.identifier,
  system_metadata: null,
  text: body.message,
  type: MessageVariant.USER,
  user: {
    id: currentUser.unique_id,
    data: {
      user_avatar: currentUser.avatar_url,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
    },
  },
  sendingStatus: MessageSendingStatus.SENDING,
});
