import { Text } from "@evvve/ui-kit";
import React from "react";
import style from "./style.module.scss";

const { list } = style;

export const PasswordRequirements = () => (
  <div>
    <Text size="m">Password should contain: </Text>
    <ul className={list}>
      <li>
        <Text size="m">Minimum 8 symbols</Text>
      </li>
      <li>
        <Text size="m">At least 1 letter</Text>
      </li>
      <li>
        <Text size="m">At least 1 number</Text>
      </li>
    </ul>
  </div>
);
