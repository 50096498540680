import React, { FC, useEffect } from "react";

import { messengerApi } from "src/store/messenger";
// import { RootState } from "src/store/store";
import { baseMessengerApi } from "src/store/messenger/api";
import { useIsFirstUpdate } from "src/shared/hooks";
import s from "./styles.module.scss";
import { useMessengerViewController, usePrefetchChatData } from "../../../../hooks";
import { MessengerViewVariant } from "../../../../types";
import { ChatListItem } from "../chat-list-item";
import ChatSpinner from "../../../chat-spinner/ChatSpinner";

const ChatList: FC = () => {
  const isFirstUpdate = useIsFirstUpdate();

  const [getChatAuthToken, { data, isUninitialized }] =
    baseMessengerApi.useGetChatAuthTokenMutation();

  const [getChatListQuery, { data: chatList, isLoading: isLoadingChatList }] =
    messengerApi.useLazyGetChatListQuery();

  const prefetcChatData = usePrefetchChatData();
  const { goToView } = useMessengerViewController();

  const goToChat = (chatId: string) => () => {
    goToView(MessengerViewVariant.CHAT_BY_ID, { chatId });
  };

  const lastEvaluatedKey = chatList?.lastEvaluatedKey || null;

  useEffect(() => {
    if (isFirstUpdate && !isUninitialized) {
      getChatAuthToken();
    }
  }, [getChatAuthToken, isFirstUpdate, isUninitialized]);

  const chatToken = data?.token || localStorage.getItem("chat-token");

  useEffect(() => {
    if (!chatToken) {
      return;
    }

    // TODO: вероятно тут тоже нужно будет делать ленивую подгрузку
    getChatListQuery({ lastEvaluatedKey });
  }, [chatToken, data?.token, getChatListQuery, lastEvaluatedKey]);

  // NOTE: тут небольшой хак, чтобы список чатов
  // правильно запрашивался с верным токеном, решение временное,
  // пока не перейдем на единый токен для приложения
  // FYI: возможно тут надо будет предусмотреть очищение стораджа
  useEffect(() => {
    if (!data?.token) return;
    localStorage.setItem("chat-token", data.token);
  }, [data?.token]);

  if (isLoadingChatList) {
    return <ChatSpinner />;
  }

  return (
    <div className={s.chatList}>
      <div className={s.wrap}>
        {chatList?.data.map((dialog) => (
          <ChatListItem
            dialogData={dialog}
            onClick={goToChat(dialog.chat_id)}
            onMouseOver={prefetcChatData(dialog.chat_id)}
            key={dialog.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatList;
