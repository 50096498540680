// FIX_ME:
/* eslint-disable import/no-cycle */
import React, { RefObject, useEffect, useState } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import { usePatchCurrentUserMutation } from "src/store/User/api";
import { setUserInfo } from "src/store/User/slice";
import { parseErrorMessage } from "src/helpers/apiHelpers";
import { addToast } from "src/store/toast/slice";
import * as Yup from "yup";
import {
  optional,
  validateFirstName,
  validateLastName,
  validateUsername,
} from "src/helpers/validation";
import { DEFAULT_NOTIFICATION_ESTIMATE, InputMaxLength } from "src/constants";
import { trimValue } from "src/helpers/text";
import { PersonalSettingsForm } from "./PersonalSettingsForm";
import { AccountSettingComponentsProps, IOutletContext } from "../../service/types";

export interface IPersonalSettingsForm {
  name: string | undefined;
  surname: string | undefined;
  company: string | undefined;
  business_role: string | undefined;
  username: string | undefined;
}

const PersonalSettings = ({ setDirty, innerRef }: AccountSettingComponentsProps) => {
  const user = useAppSelector((state) => state.user.userInfo);
  const dispatch = useAppDispatch();
  const [patchCurrentUser, { isLoading: isLoadingPatchUser }] =
    usePatchCurrentUserMutation();
  const [formikRef, setFormikRef] =
    useState<RefObject<FormikProps<IPersonalSettingsForm>>>();
  const OutletObject = useOutletContext<IOutletContext>();

  const handleOnSubmit = (
    values: IPersonalSettingsForm,
    { resetForm, setFieldError }: FormikHelpers<IPersonalSettingsForm>,
  ) => {
    const valuesRequest = {
      first_name: trimValue(values.name),
      last_name: trimValue(values?.surname),
      username: trimValue(values?.username),
      company: trimValue(values?.company),
      position: trimValue(values?.business_role),
    };
    patchCurrentUser(valuesRequest)
      .unwrap()
      .then((response) => {
        dispatch(setUserInfo(response));
        resetForm();
        dispatch(
          addToast({
            type: "default",
            content: "Changes saved",
            estimate: DEFAULT_NOTIFICATION_ESTIMATE,
          }),
        );
      })
      .catch((error) => {
        const errorData = parseErrorMessage(error);
        if (errorData) {
          if (errorData.code === "username_already_exists") {
            setFieldError("username", errorData.message);
          } else alert(errorData.message);
        }
      });
  };

  const validationSchema = Yup.object().shape({
    name: validateFirstName(),
    surname: validateLastName(),
    username: validateUsername(),
    company: optional(InputMaxLength.S_24),
    business_role: optional(InputMaxLength.S_24),
  });

  const initialValues: IPersonalSettingsForm = {
    name: user?.first_name ?? undefined,
    surname: user?.last_name ?? undefined,
    company: user?.company ?? undefined,
    business_role: user?.position ?? undefined,
    username: user?.username ?? undefined,
  };

  useEffect(() => {
    // mobile
    if (innerRef) {
      setFormikRef(innerRef);
    }
    // desktop
    if (OutletObject?.personalRef) {
      setFormikRef(OutletObject.personalRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange={false}
    >
      {(props) => (
        <PersonalSettingsForm
          {...props}
          isLoadingPatchUser={isLoadingPatchUser}
          setDirty={setDirty}
        />
      )}
    </Formik>
  );
};
export default React.memo(PersonalSettings);
