import { useCallback, useEffect } from "react";
import { ChatVariant, messengerApi } from "src/store/messenger";
import { useIsFirstUpdate } from "src/shared/hooks";
import { useMessengerViewController } from "../../hooks";
import { BottomBar, ChatHeader, MessageList } from "./ui";
import ChatSpinner from "../chat-spinner/ChatSpinner";

const ChatRoom = () => {
  const { goToChatList, chatId } = useMessengerViewController();
  const isFirstUpdate = useIsFirstUpdate();

  const [
    getChatInfoQuery,
    {
      data: chatInfoData,
      isLoading: chatInfoByIdIsLoading,
      isError: chatInfoByIdIsError,
    },
  ] = messengerApi.useLazyGetChatInfoByIdQuery();

  const isSystemChat = chatInfoData?.system;

  const fetchData = useCallback(() => {
    if (chatId) getChatInfoQuery({ chatId });
    else throw new Error("[CHAT]: There is no  chatId");
  }, [chatId, getChatInfoQuery]);

  useEffect(() => {
    if (isFirstUpdate) fetchData();
  }, [fetchData, isFirstUpdate, chatId]);

  useEffect(() => {
    if (!chatId) goToChatList();
  }, [chatId, goToChatList]);

  useEffect(() => {
    if (chatInfoByIdIsError) goToChatList();
  }, [chatInfoByIdIsError, goToChatList]);

  return (
    <>
      <ChatHeader
        chatInfo={chatInfoData}
        chatInfoIsLoading={chatInfoByIdIsLoading}
      />
      {chatId ? (
        <MessageList key="message-list" chatInfo={chatInfoData} />
      ) : (
        <ChatSpinner />
      )}
      {/* [CHAT] TODO: при переходе на view делать инпут автоматически активным ? После релиза */}
      {!isSystemChat && (
        <BottomBar
          key="message-input-control"
          chatVariant={chatInfoData?.type || ChatVariant.SUPPORT}
        />
      )}
    </>
  );
};

export default ChatRoom;
