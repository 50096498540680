import { useEffect, useMemo } from "react";
import { messengerApi } from "src/store/messenger";
import { useAppSelector } from "src/hooks/useStoreHooks";
import { useLazyGetUserQuery } from "src/store/User/api";
import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";

type HookArgs = { skip?: boolean; chatId?: string };

export const useGetChatMateComplex = (args?: HookArgs) => {
  const { chatId: controllerChatId, profileId } = useMessengerViewController();
  const chatId = args?.chatId || controllerChatId;
  const currentUser = useAppSelector((state) => state.user.userInfo);

  const [
    getChatParticipantsQuery,
    {
      data: participants,
      isUninitialized: isUninitializedParticipants,
      isLoading: isLoadingParticipants,
    },
  ] = messengerApi.useLazyGetChatParticipantsQuery();

  const [
    getUserProfile,
    {
      data: chatMateProfile,
      isUninitialized: isUninitializedUserProfile,
      isLoading: isLoadingUserProfile,
    },
  ] = useLazyGetUserQuery();

  const chatMateId = useMemo(() => {
    if (profileId) return profileId;

    const chatMateItem = participants?.find(
      (participant) => participant.id !== currentUser?.unique_id,
    );

    return chatMateItem?.id;
  }, [currentUser?.unique_id, participants, profileId]);

  useEffect(() => {
    if (!chatId) return;

    if (isUninitializedParticipants && !args?.skip) {
      getChatParticipantsQuery({ chatId });
    }
  }, [args?.skip, chatId, getChatParticipantsQuery, isUninitializedParticipants]);

  useEffect(() => {
    if (chatMateId && isUninitializedUserProfile && !args?.skip) {
      getUserProfile({ userId: chatMateId });
    }
  }, [args?.skip, chatMateId, getUserProfile, isUninitializedUserProfile]);

  return {
    chatMateId,
    chatMateProfile,
    isLoading: isLoadingParticipants || isLoadingUserProfile,
  };
};
