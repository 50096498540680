// FIX_ME:
/* eslint-disable import/no-cycle */
/* eslint-disable operator-assignment */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentUser } from "./types";
import { ResponsiveLayoutProps } from "../../hooks/useResponsiveLayout";

export interface User {
  userInfo: CurrentUser | null;
  responsiveLayoutConfig: ResponsiveLayoutProps | null;
}

const initialState: User = {
  userInfo: null,
  responsiveLayoutConfig: null,
};

const currentUser = createSlice({
  name: "user",
  initialState,
  reducers: {
    setResponsiveLayoutConfig(
      state,
      responsiveLayoutProps: PayloadAction<ResponsiveLayoutProps>,
    ) {
      state.responsiveLayoutConfig = responsiveLayoutProps.payload;
    },
    setUserInfo(state, action: PayloadAction<CurrentUser | null>) {
      state.userInfo = action.payload;
    },
    logout() {
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("chat-token");
    },
    incrementFollowingCount(state) {
      if (typeof state.userInfo?.following_count === "number")
        state.userInfo.following_count = state.userInfo.following_count + 1;
    },
    decrementFollowingCount(state) {
      if (typeof state.userInfo?.following_count === "number")
        state.userInfo.following_count = state.userInfo.following_count - 1;
    },
  },
});
export const {
  setUserInfo,
  logout,
  incrementFollowingCount,
  decrementFollowingCount,
  setResponsiveLayoutConfig,
} = currentUser.actions;

export default currentUser.reducer;
