import { appApi } from "src/api/api";
import { currentUserApi } from "src/store/User/api";
import { AppDispatch, RootState } from "src/store/store";

export type UserState = ReturnType<typeof appApi.reducer>;

export const getCurrentUser = async (state: RootState, dispatch: AppDispatch) => {
  const currentUser = currentUserApi.endpoints.getCurrentUser.select()(state).data;

  if (!currentUser) {
    return dispatch(currentUserApi.endpoints.getCurrentUser.initiate()).unwrap();
  }

  return currentUser;
};
