// FIX_ME:
/* eslint-disable import/no-cycle */
import React, { RefObject, useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikProps } from "formik/dist/types";
import { useOutletContext } from "react-router-dom";
import {
  useGetPrivacySettingsQuery,
  usePatchPrivacySettingsMutation,
} from "src/store/User/api";
import { PrivacyPublicity } from "src/store/User/types";
import { parseErrorMessage } from "src/helpers/apiHelpers";
import { addToast } from "src/store/toast/slice";
import { useAppDispatch } from "src/hooks/useStoreHooks";
import { AccountSettingComponentsProps, IOutletContext } from "../../service/types";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../../../../constants";
import { PrivacySettingsForm } from "./PrivacySettingsForm";
import { sendAmplitudeData } from "../../../../../helpers/amplitude";

export interface IPrivacySettingsForm {
  profileType: PrivacyPublicity;
  showEventsRoles: string[];
  startChat: boolean;
}

const Privacy = ({ setDirty, innerRef }: AccountSettingComponentsProps) => {
  const {
    data: userPrivacySettings,
    isLoading: isUserPrivacyLoading,
    isFetching: isFetchingUserPrivacySettings,
    refetch: refetchPrivacy,
  } = useGetPrivacySettingsQuery(undefined, { refetchOnMountOrArgChange: true });

  const [patchPrivacySettings, { isLoading: isPatchUserPrivacyLoading }] =
    usePatchPrivacySettingsMutation();
  const OutletObject = useOutletContext<IOutletContext>();
  const dispatch = useAppDispatch();
  const [formikRef, setFormikRef] =
    useState<RefObject<FormikProps<IPrivacySettingsForm>>>();
  const [previousProfileType, setPreviousProfileType] = useState(
    userPrivacySettings?.publicity,
  );

  const handleOnSubmit = (
    values: IPrivacySettingsForm,
    { resetForm }: FormikHelpers<IPrivacySettingsForm>,
  ) => {
    const valuesRequest = {
      publicity: values.profileType,
      show_organizer_events: true,
      show_speaker_events: true,
      show_attendee_events: true,
      allow_others_to_start_chat: values.startChat,
    };
    patchPrivacySettings(valuesRequest)
      .unwrap()
      .then(() => {
        if (values.profileType === "private" && previousProfileType === "public") {
          sendAmplitudeData("Switch privacy to private type");
        } else if (
          values.profileType === "public" &&
          previousProfileType === "private"
        ) {
          sendAmplitudeData("Switch privacy to public type");
        }
        dispatch(
          addToast({
            type: "default",
            content: "Changes saved",
            estimate: DEFAULT_NOTIFICATION_ESTIMATE,
          }),
        );
        refetchPrivacy().then(() => {
          resetForm();
        });
      })
      .catch((error) => {
        const errorData = parseErrorMessage(error);
        if (errorData) {
          alert(errorData.message);
        }
      });
  };

  const isLoading =
    isUserPrivacyLoading ||
    isPatchUserPrivacyLoading ||
    isFetchingUserPrivacySettings;

  const showEventRoles = () => {
    const result = [];
    if (userPrivacySettings?.show_speaker_events === true) {
      result.push("speaker");
    }
    if (userPrivacySettings?.show_attendee_events === true) {
      result.push("attendee");
    }
    if (userPrivacySettings?.show_organizer_events === true) {
      result.push("organizer");
    }

    return result;
  };

  const initialState: IPrivacySettingsForm = {
    showEventsRoles: showEventRoles(),
    profileType: userPrivacySettings?.publicity ?? "public",
    startChat: userPrivacySettings?.allow_others_to_start_chat ?? true,
  };

  useEffect(() => {
    setPreviousProfileType(userPrivacySettings?.publicity);
  }, [userPrivacySettings?.publicity]);

  useEffect(() => {
    // mobile
    if (innerRef) {
      setFormikRef(innerRef);
    }
    // desktop
    if (OutletObject?.privacyRef) {
      setFormikRef(OutletObject.privacyRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik<IPrivacySettingsForm>
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialState}
      onSubmit={handleOnSubmit}
    >
      {(props) => (
        <PrivacySettingsForm
          {...props}
          setDirty={setDirty}
          isGetUserPrivacyLoading={isLoading}
          isPatchUserPrivacyLoading={isPatchUserPrivacyLoading}
          // initialFormState={initialState}
        />
      )}
    </Formik>
  );
};

export default React.memo(Privacy);
