import { useEffect, useState } from "react";
import { useMobileType } from "./useMobileType";
import { useDesktopType } from "./useDesktopType";
import { useTabletType } from "./useTabletType";
import { useDeviceDetector } from "../broadcasting/hooks/useDeviceDetector";

export interface ResponsiveLayoutProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const useResponsiveLayout = () => {
  const { userDevice } = useDeviceDetector();

  const isMobileViewport = useMobileType();
  const isDesktopViewport = useDesktopType();
  const isTabletOrDesktopViewport = useTabletType();

  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isTabletViewport = isTabletOrDesktopViewport && !isDesktopViewport;

    // DevTools set userDevice to "smartphone", so check both userDevice and viewport
    const isDesktopViewportOnDesktopViaDevTools =
      isDesktopViewport && userDevice === "smartphone";
    const isTabletViewportOnDesktop =
      isTabletViewport && (userDevice === "desktop" || userDevice === "smartphone");
    const isMobileViewportOnDesktop = isMobileViewport && userDevice === "desktop";

    const isDesktopDesign =
      (userDevice === "desktop" && isDesktopViewport) ||
      isDesktopViewportOnDesktopViaDevTools;
    const isTabletDesign = userDevice === "tablet" || isTabletViewportOnDesktop;
    const isMobileDesign =
      (userDevice === "smartphone" && !isDesktopDesign && !isTabletDesign) ||
      isMobileViewportOnDesktop;

    setIsDesktop(isDesktopDesign);
    setIsTablet(isTabletDesign);
    setIsMobile(isMobileDesign);
  }, [userDevice, isDesktopViewport, isTabletOrDesktopViewport, isMobileViewport]);

  return isMobile || isTablet || isDesktop
    ? { isMobile, isTablet, isDesktop }
    : null;
};
