import { FC, ReactElement } from "react";
import { Icons } from "@evvve/ui-kit";
import { ChatVariant, messengerApi, type ChatDTO } from "src/store/messenger";
import { MessengerViewVariant } from "src/close-part/features/Messenger/types";
import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";
import { noop } from "lodash";
import s from "./styles.module.scss";
import { AvatarBlock } from "../avatar-block";
import { TitleBlock } from "../title-block";
import { ChatParticipantsCounter } from "../chat-participants-counter";

type Props = {
  chatInfo?: ChatDTO;
  menuButtonEl?: ReactElement;
  chatMateId?: string;
  isSystemChat?: boolean;
  isLoading: boolean;
};

const CommonHeader: FC<Props> = ({
  chatInfo,
  menuButtonEl,
  chatMateId,
  isSystemChat,
  isLoading,
}) => {
  const { goToView, goToChatList } = useMessengerViewController();
  const prefetchChatList = messengerApi.usePrefetch("getChatList");

  const isPrivateChat = chatInfo && chatInfo.type === ChatVariant.PRIVATE;

  const goToProfileView = () => {
    if (isPrivateChat && chatMateId) {
      goToView(MessengerViewVariant.PROFILE, { profileId: chatMateId });
    } else {
      throw new Error(
        "[CHAT]: There is no chatMate and it is group or support chat",
      );
    }
  };

  const goToParticipants = () => {
    goToView(MessengerViewVariant.CHAT_PARTICIPANTS);
  };

  const prefetchData = () => {
    prefetchChatList({ lastEvaluatedKey: null });
  };

  const shouldShowParticipantsCounter = !isSystemChat && !isPrivateChat;

  return (
    <header className={s.header}>
      <div className={s.left_side}>
        <div className={s.avtar_arrow}>
          <div
            onClick={goToChatList}
            onKeyDown={noop}
            role="button"
            tabIndex={0}
            aria-labelledby="TODO:"
            onMouseOver={prefetchData}
            onFocus={noop}
          >
            <Icons.ChevronLeft size="m" color="violet600" />
          </div>
          <AvatarBlock
            avatarUrl={chatInfo?.avatar_url}
            title={chatInfo?.title}
            isPlaceholderView={!chatInfo || isLoading}
            onClick={goToProfileView}
          />
        </div>
        <div className={s.textWrapper}>
          <TitleBlock isPlaceholderView={!chatInfo || isLoading}>
            {chatInfo?.title}
          </TitleBlock>
          <ChatParticipantsCounter
            onClick={goToParticipants}
            shouldShow={shouldShowParticipantsCounter}
          />
        </div>
      </div>
      {menuButtonEl}
    </header>
  );
};

export default CommonHeader;
