import { Spinner } from "@evvve/ui-kit";
import React from "react";
import s from "./styles.module.scss";

const ChatSpinner = () => (
  <div className={s.spinnerWrapper}>
    <Spinner size="l" color="grayscale400" />
  </div>
);

export default ChatSpinner;
