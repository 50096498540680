// FIX_ME:
/* eslint-disable react/no-array-index-key */
import React from "react";
import ReactDOM from "react-dom";
import { Button, Spinner } from "@evvve/ui-kit";
import { noop } from "lodash";
import styles from "./style.module.scss";
import { ContextMenuTypes } from "./types";

type Action = {
  type: ContextMenuTypes;
  handler?: (() => void) | null;
  isLoading?: boolean;
};

type ButtonTheme = React.ComponentProps<typeof Button> & { caption: string };

const BUTTONS_THEMES: Record<ContextMenuTypes, ButtonTheme> = {
  [ContextMenuTypes.DeleteDialog]: {
    theme: "red",
    type: "flat",
    size: "s",
    caption: "Delete dialog",
  },
  [ContextMenuTypes.LeaveGroup]: {
    theme: "red",
    type: "flat",
    size: "s",
    caption: "Leave chat",
  },
  [ContextMenuTypes.ViewAllParticipants]: {
    theme: "violet",
    type: "flat",
    size: "s",
    caption: "View all participants",
  },
  [ContextMenuTypes.ViewProfile]: {
    theme: "violet",
    type: "flat",
    size: "s",
    caption: "View profile",
  },
  [ContextMenuTypes.FollowUser]: {
    theme: "violet",
    type: "flat",
    size: "s",
    caption: "Follow",
  },
  [ContextMenuTypes.UnfollowUser]: {
    theme: "violet",
    type: "flat",
    size: "s",
    caption: "Unfollow",
  },
};

export interface Props {
  onClickBlockingArea?: React.MouseEventHandler<HTMLDivElement>;
  position?: {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
  };
  actions?: Action[];
}

const ContextMenu = ({ onClickBlockingArea, position, actions }: Props) =>
  ReactDOM.createPortal(
    <>
      <div
        className={styles.preventClickArea}
        onClick={onClickBlockingArea}
        onKeyDown={noop}
        role="button"
        tabIndex={0}
        aria-labelledby="TODO:"
      />
      <div
        style={{
          zIndex: 99999,
          position: "fixed",
          left: position?.left,
          top: position?.top,
          right: position?.right,
          bottom: position?.bottom,
        }}
      >
        <div className={styles.wrap}>
          {actions?.map((a, i) =>
            !a.isLoading ? (
              <Button
                key={i}
                theme={BUTTONS_THEMES[a.type].theme}
                type={BUTTONS_THEMES[a.type].type}
                size={BUTTONS_THEMES[a.type].size}
                onClick={a.handler || undefined}
              >
                {BUTTONS_THEMES[a.type].caption}
              </Button>
            ) : (
              <div key={i} className={styles.spinnerWrapper}>
                <Spinner size="l" color="grayscale400" />
              </div>
            ),
          )}
        </div>
      </div>
    </>,
    document.body,
  );
export default ContextMenu;
