// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { appApi } from "../../api/api";
import {
  ChangeEmailResponse,
  CurrentUser,
  IPassword,
  IUpdatePassword,
  PatchCurrentUser,
  PrivacySettings,
  UploadImageKey,
  GetUserResponse,
  UserAnalyticsResponse,
} from "./types";
import { PageResponse } from "../store";

export const currentUserApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => "/users/me",
    }),
    getUser: builder.query<GetUserResponse, { userId?: string; username?: string }>({
      query: ({ userId, username }) => ({
        url: "/users",
        params: {
          unique_id: userId,
          username,
        },
      }),
      providesTags: (profile) => [
        { type: "UserProfile", id: profile?.user.unique_id },
      ],
    }),
    findUsersByUsername: builder.query<PageResponse<CurrentUser>, string>({
      query: (username: string) => ({
        url: "/users/find",
        params: { username, page: 1, size: 50 },
      }),
    }),

    followUnfollowUser: builder.mutation<
      { status: boolean },
      { amIFollower: boolean; uniqueId: string }
    >({
      query: ({ uniqueId, amIFollower }) =>
        amIFollower ? `/users/${uniqueId}/unfollow` : `/users/${uniqueId}/follow`,
      invalidatesTags: (_, __, { uniqueId }) => [
        { type: "UserProfile", id: uniqueId },
      ],
    }),

    followUser: builder.query<{ status: boolean }, { uniqueId: string }>({
      query: ({ uniqueId }) => `/users/${uniqueId}/follow`,
    }),
    unfollowUser: builder.query<{ status: boolean }, { uniqueId: string }>({
      query: ({ uniqueId }) => `/users/${uniqueId}/unfollow`,
    }),
    patchCurrentUser: builder.mutation<CurrentUser, PatchCurrentUser>({
      query: (userInfo: PatchCurrentUser) => ({
        url: "/users/me",
        method: "PATCH",
        body: userInfo,
      }),
    }),
    uploadAvatar: builder.mutation<string | null, UploadImageKey>({
      query: (key: UploadImageKey) => ({
        url: "/users/me/upload-avatar",
        method: "POST",
        body: key,
      }),
    }),
    getDefaultAvatar: builder.query<{ url: string }, void>({
      query: () => "/users/me/default-avatar",
    }),
    getPrivacySettings: builder.query<PrivacySettings, void>({
      query: () => ({
        url: "/users/me/privacy-settings",
      }),
    }),
    patchPrivacySettings: builder.mutation<PrivacySettings, PrivacySettings>({
      query: (data: PrivacySettings) => ({
        url: "/users/me/privacy-settings",
        method: "PATCH",
        body: data,
      }),
    }),
    setPassword: builder.mutation<string, IPassword>({
      query: (data: IPassword) => ({
        url: "/users/me/set-password",
        method: "POST",
        body: data,
      }),
    }),
    updatePassword: builder.mutation<PrivacySettings, IUpdatePassword>({
      query: (data: IUpdatePassword) => ({
        url: "/users/me/update-password",
        method: "POST",
        body: data,
      }),
    }),
    deletePassword: builder.mutation<string, IPassword>({
      query: (data: IPassword) => ({
        url: "/users/me/delete-password",
        method: "POST",
        body: data,
      }),
    }),
    requestCodeChangeEmail: builder.mutation<ChangeEmailResponse, void>({
      query: () => ({
        url: "/users/me/change-email/request-code",
        method: "POST",
      }),
    }),
    validateCodeChangeEmail: builder.mutation<ChangeEmailResponse, { code: string }>(
      {
        query: (data: { code: string }) => ({
          url: "/users/me/change-email/validate-code",
          method: "POST",
          body: data,
        }),
      },
    ),
    requestNewEmailCode: builder.mutation<
      ChangeEmailResponse,
      { new_email: string }
    >({
      query: (data: { new_email: string }) => ({
        url: "/users/me/change-email/request-new-email-code",
        method: "POST",
        body: data,
      }),
    }),
    validateNewEmailCode: builder.mutation<ChangeEmailResponse, { code: string }>({
      query: (data: { code: string }) => ({
        url: "/users/me/change-email/validate-new-email-code",
        method: "POST",
        body: data,
      }),
    }),
    deleteAccount: builder.mutation<{ status: string }, void>({
      query: () => ({
        url: "/users/me/delete-account",
        method: "POST",
      }),
    }),
    getUserAnalytics: builder.query<UserAnalyticsResponse, void>({
      query: () => ({
        url: "/users/me/analytics",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyFindUsersByUsernameQuery,
  useLazyFollowUserQuery,
  useLazyUnfollowUserQuery,
  useLazyGetCurrentUserQuery,
  usePatchCurrentUserMutation,
  useUploadAvatarMutation,
  useGetPrivacySettingsQuery,
  usePatchPrivacySettingsMutation,
  useSetPasswordMutation,
  useDeletePasswordMutation,
  useUpdatePasswordMutation,
  useRequestCodeChangeEmailMutation,
  useValidateCodeChangeEmailMutation,
  useRequestNewEmailCodeMutation,
  useValidateNewEmailCodeMutation,
  useDeleteAccountMutation,
  useGetDefaultAvatarQuery,
  useGetUserAnalyticsQuery,
  useLazyGetUserAnalyticsQuery,
  useFollowUnfollowUserMutation,
  endpoints: userApiEndpoints,
} = currentUserApi;
