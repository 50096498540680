// FIX_ME:
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link, LinkProps as LinkComponentProps } from "@evvve/ui-kit";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { noop } from "lodash";
import cn from "classnames";
import style from "./style.module.scss";

export type LinkTarget = "_blank" | "_parent" | "_self" | "_top";

interface LinkProps extends LinkComponentProps {
  to: string;
  external?: boolean;
  nbsp?: boolean;
  target?: LinkTarget;
  onClick?: () => void;
  onContextMenu?: () => void;
  title?: string;
  isFlex?: boolean;
  className?: string;
}

const LinkBlock = (props: LinkProps) => {
  const location = useLocation();
  const {
    to,
    external = false,
    nbsp = false,
    target = "_self",
    onClick,
    onContextMenu,
    title,
    children,
    isFlex = false,
    className,
  } = props;

  const getLink = () => (
    <Link {...props}>
      <>
        {nbsp && "\u00A0"}
        {children}
        {nbsp && "\u00A0"}
      </>
    </Link>
  );

  const propsRouterLink = {
    to,
    target,
    ...(location?.pathname && { state: { prevPath: location.pathname } }),
  };

  return (
    <div
      className={cn(style.wrap, className)}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onKeyDown={noop}
      role="button"
      tabIndex={0}
    >
      {!external ? (
        <RouterLink {...propsRouterLink} rel="noreferrer">
          {getLink()}
        </RouterLink>
      ) : (
        <a
          href={to}
          target={target}
          title={title}
          className={cn({ [style.flex]: isFlex })}
        >
          {getLink()}
        </a>
      )}
    </div>
  );
};

export default LinkBlock;
