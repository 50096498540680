// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect, useState } from "react";
import { Icons, Text } from "@evvve/ui-kit";
import { getStringNotBreakingSpace } from "src/helpers/text";
import { IconProps } from "@evvve/ui-kit/dist/components/Icon/IconWrap";
import { noop } from "lodash";
import Popven from "./Popven";
import style from "./style.module.scss";
import { useDeviceDetector } from "../../../broadcasting/hooks/useDeviceDetector";

// FIX_ME:
// eslint-disable-next-line @typescript-eslint/naming-convention
const { wrap, tooltip, tooltip__visible } = style;
const { AlertCircle } = Icons;

export type TooltipLocation = "left" | "right" | "top" | "bottom";

interface Props {
  children?: React.ReactNode | string[];
  customTrigger?: React.ReactNode;
  isActive?: boolean;
  // location: TooltipLocation;
  locationsByResolution?: {
    mobile: TooltipLocation;
    mobileLandscape: TooltipLocation;
    tablet: TooltipLocation;
    desktop: TooltipLocation;
  };
  iconColor?: IconProps["color"];
  size?: "s" | "m";
  className?: string;
  classNameTip?: string;
  isNotBold?: boolean;
}

const Tooltip = ({
  children,
  customTrigger,
  isActive = true,
  // location: specifiedLocation,
  iconColor = "grayscale500",
  size = "m",
  className,
  classNameTip,
  isNotBold = false,
  locationsByResolution,
}: Props) => {
  const [location, setLocation] = useState<TooltipLocation>("right");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { userDevice } = useDeviceDetector();

  const isArrayChildren = Array.isArray(children);
  let newChildren;
  if (isArrayChildren && !isNotBold) {
    newChildren = children.map((str) => (
      <div key={str}>
        {str.split(" ").map((text) => {
          const isBold =
            text[0] === "'" &&
            (text[text.length - 1] === "'" || text[text.length - 2] === "'");
          const newText = isBold ? text.split("'").join("") : text;
          const newComponentText = isBold ? (
            <b key={`${newText}${str}`}>{newText}</b>
          ) : (
            text
          );

          return (
            <React.Fragment key={`${newText}${str}`}>
              {newComponentText}{" "}
            </React.Fragment>
          );
        })}
      </div>
    ));
  }
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    function handleWindowResize() {
      if (locationsByResolution) {
        const { mobile, mobileLandscape, tablet, desktop } = locationsByResolution;
        const windowWidth = window.innerWidth;

        if (windowWidth < 480) {
          setLocation(mobile);
        } else if (windowWidth < 768) {
          setLocation(mobileLandscape);
        } else if (windowWidth < 1280) {
          setLocation(tablet);
        } else {
          setLocation(desktop);
        }
      }
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsTooltipVisible(false);
      }
    }

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getChildren = () =>
    isArrayChildren ? getStringNotBreakingSpace(children, true) : children;

  const tooltipClass = `tooltip__${location}${size === "s" ? "S" : "M"}`;
  const tooltipStyle = style[tooltipClass];

  return (
    <div
      className={`${wrap} ${className}`}
      ref={ref}
      onKeyDown={noop}
      role="button"
      tabIndex={0}
      onClick={(event) => {
        if (userDevice === "smartphone" || userDevice === "tablet") {
          setIsTooltipVisible(!isTooltipVisible);
          event.stopPropagation();
        }
      }}
    >
      {customTrigger || <AlertCircle color={iconColor} size={size} />}
      {isActive && (
        <div
          className={`
          ${tooltip}
          ${tooltipStyle}
          ${classNameTip}
          ${isTooltipVisible ? tooltip__visible : ""}
        `}
        >
          <Popven placement={location}>
            <Text as="div" size="s">
              {newChildren || getChildren()}
            </Text>
          </Popven>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
