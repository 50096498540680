/* eslint-disable implicit-arrow-linebreak */
import React, { FC } from "react";
import { Text } from "@evvve/ui-kit";

import s from "./styles.module.scss";

type Props = {
  unreadMessagesCount?: number;
};

const Counter: FC<Props> = ({ unreadMessagesCount = 0 }) =>
  unreadMessagesCount > 0 ? (
    <div className={s.unread}>
      <Text color="white" size="xs">
        {unreadMessagesCount}
      </Text>
    </div>
  ) : null;

export default Counter;
