import { FC } from "react";

import { Icons, Text } from "@evvve/ui-kit";

import Link from "src/shared-features/Link";

import cn from "classnames";
import { WelcomeToTheEventMetadata } from "src/store/messenger";
import systemNotificationsStyles from "../style.module.scss";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const { linkText } = systemNotificationsStyles;

type Props = {
  metadata: WelcomeToTheEventMetadata;
  text?: string;
};

const WelcomeToEvent: FC<Props> = ({ metadata, text }) => {
  const { event_id: eventId, event_name: eventTitle } = metadata.data;

  return (
    <NotificationTemplate>
      <div className={styles.info}>
        <Icons.Info color="tangerine1000" size="s" />
        <Text size="m" color="tangerine1000">
          Welcome to the event
        </Text>
      </div>
      <Link
        className={cn(styles.eventTitle, linkText)}
        size="m"
        color="violet"
        to={`/catalog/event/${eventId}`}
        underline={false}
        hoverUnderLine={false}
      >
        {eventTitle}
      </Link>
      {text && (
        <Text className={styles.customText} size="xs" color="grayscale800" as="span">
          {text}
        </Text>
      )}
    </NotificationTemplate>
  );
};

export default WelcomeToEvent;
