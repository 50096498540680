import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type OtherUserState = {
  userToRefetch: {
    userId: string | null;
    username: string | null;
  };
};

const initialState: OtherUserState = {
  userToRefetch: {
    userId: null,
    username: null,
  },
};

const otherUserSlice = createSlice({
  name: "otherUser",
  initialState,
  reducers: {
    refetchUser(
      state,
      { payload }: PayloadAction<{ userId: string; username: string }>,
    ) {
      state.userToRefetch = { userId: payload.userId, username: payload.username };
    },
  },
});

export const { refetchUser } = otherUserSlice.actions;

export default otherUserSlice.reducer;
