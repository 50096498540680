// NOTE: патч для респонсив <textarea /> - патч обеспечивает автоматическое
// увеличение/уменьшение высоты DOM-ноды в зависимости от количества введенных
// пользователем строк

// NOTE: Ниже представлен слегка переработанный код решения: https://github.com/cozy-web/textarea-max-rows
type Attributes = {
  [key: string]: string | number | null | undefined;
};

const createElement = (tagName: string, attributes: Attributes): HTMLElement => {
  const element = document.createElement(tagName);

  Object.entries(attributes).forEach(([key, value]) => {
    if (value) {
      element.setAttribute(key, value.toString());
    }
  });

  return element;
};

const shapeElement = (element: HTMLElement, attributes: Attributes): HTMLElement => {
  const newElement = element.cloneNode(true) as HTMLElement; // Клонируем элемент, чтобы не модифицировать исходный

  Object.entries(attributes).forEach(([key, value]) => {
    if (value) {
      newElement.setAttribute(key, value.toString());
    }
  });

  return newElement;
};

const moveElementOffscreen = (element: HTMLElement): HTMLElement => {
  const move = () => {
    Object.assign(element.style, {
      position: "absolute",
      left: `-${document.body.clientWidth * 2}px`,
    });
  };

  move();
  window.addEventListener("resize", move);

  return element;
};

const syncWidthFrom = (
  newElement: HTMLElement,
  existingElement: HTMLElement,
): ResizeObserver => {
  const resizeObserver = new ResizeObserver(([entry]) => {
    const width = entry.target instanceof HTMLElement ? entry.target.offsetWidth : 0;

    // NOTE: необходимо иметь доступ к ноде непосредственно, чтобы влиять на высоту textarea
    // eslint-disable-next-line no-param-reassign
    newElement.style.width = `${width}px`;
  });

  resizeObserver.observe(existingElement);

  return resizeObserver;
};

const insertAfter = (newNode: Node, existingNode: Node): void => {
  existingNode.parentNode?.insertBefore(newNode, existingNode.nextSibling);
};

const patchTextareaMaxRowsSupport = (
  element: HTMLTextAreaElement,
  { shadowElement = null }: { shadowElement?: HTMLTextAreaElement | null } = {},
): void => {
  const attrClass = element.getAttribute("class");
  const attrStyle = element.getAttribute("style");
  const attrRows = element.getAttribute("rows") || "1";
  const attrMaxRows = element.getAttribute("max-rows");

  const minRows = Number.parseInt(attrRows, 10);
  const maxRows = Number.parseInt(attrMaxRows ?? "0", 10);

  const attributes: Attributes = {
    class: attrClass,
    style: `box-sizing: border-box !important; ${attrStyle}`,
    rows: attrRows,
    "max-rows": attrMaxRows,
  };

  let shadow = shadowElement;

  if (!shadow) {
    shadow = createElement("textarea", attributes) as HTMLTextAreaElement;
  } else {
    shadow = shapeElement(shadow, attributes) as HTMLTextAreaElement;
  }

  moveElementOffscreen(shadow);
  syncWidthFrom(shadow, element);

  if (!shadowElement) {
    insertAfter(shadow, element);
  }

  const syncRows = (): void => {
    if (!shadow) return;

    // copy the content from the real textarea
    shadow.value = element.value;

    // get the height of content
    shadow.setAttribute("rows", "1");
    const contentHeight = shadow.scrollHeight;

    // increase the number of rows until finding a proper number.
    for (let rows = minRows; rows <= maxRows; rows++) {
      shadow.setAttribute("rows", rows.toString());

      if (shadow.clientHeight >= contentHeight) {
        break;
      }
    }

    const oldRows = element.getAttribute("rows");
    const newRows = shadow.getAttribute("rows");
    element.setAttribute("rows", newRows ?? "1");

    if (oldRows !== newRows) {
      const event = new CustomEvent("rows-change", {
        detail: { rows: Number.parseInt(newRows ?? "1", 10) },
      });
      element.dispatchEvent(event);
    }
  };

  element.addEventListener("input", syncRows);

  // Override original getter and setter to call syncRows() when the value is set by JavaScript.
  const descriptor = Object.getOwnPropertyDescriptor(
    HTMLTextAreaElement.prototype,
    "value",
  );

  const get = descriptor?.get;
  const set = descriptor?.set;

  if (get && set) {
    Object.defineProperty(element, "value", {
      get() {
        return get.call(this);
      },
      set(value: string) {
        set.call(this, value);

        const event = new CustomEvent("value-change", {
          detail: { value },
        });
        element.dispatchEvent(event);

        syncRows();
      },
    });
  }
};

export default patchTextareaMaxRowsSupport;
