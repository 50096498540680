import React, { FC } from "react";

import { Icons, Text } from "@evvve/ui-kit";

import Link from "src/shared-features/Link";

import { IWillNotBeASpeakerAtTheEventMetadata } from "src/store/messenger";
import systemNotificationsStyles from "../style.module.scss";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const { linkText } = systemNotificationsStyles;

type Props = {
  metadata: IWillNotBeASpeakerAtTheEventMetadata;
};

const IWillNotBeSpeaker: FC<Props> = ({ metadata }) => {
  const { event_id: eventId, event_name: eventTitle } = metadata.data;

  return (
    <NotificationTemplate>
      <div className={styles.info}>
        <Icons.Info color="tangerine1000" size="s" />
        <Text size="m" color="tangerine1000">
          I won't be a speaker at the event
        </Text>
      </div>
      <Link
        className={[styles.eventTitle, linkText].join(" ")}
        size="m"
        color="violet"
        to={`/catalog/event/${eventId}`}
        underline={false}
        hoverUnderLine={false}
      >
        {eventTitle}
      </Link>
    </NotificationTemplate>
  );
};

export default IWillNotBeSpeaker;
