// FIX_ME:
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, PropsWithChildren, useState } from "react";
import { Outlet } from "react-router-dom";
import { SelectItemType } from "@evvve/ui-kit/dist/components/Select/SelectItemType";
import { DeviceType } from "device-detector-js/dist/typings/device";

export interface StreamDevicesSettings {
  userDevice: DeviceType;
  isCameraOn: boolean;
  isMicroOn: boolean;
  isSharingScreen: boolean;
  selectedCamera?: SelectItemType;
  selectedMicro?: SelectItemType;
  videoDevices?: MediaDeviceInfo[];
  audioDevices?: MediaDeviceInfo[];
  cameraPermissions: boolean;
  microPermissions: boolean;
}

type BroadcastMetadata = {
  deviceSettings: StreamDevicesSettings | undefined;
  setDeviceSettings: React.Dispatch<
    React.SetStateAction<StreamDevicesSettings | undefined>
  >;
};
export const BroadcastContext = createContext<BroadcastMetadata | null>(null);

export const BroadcastWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const [deviceSettings, setDeviceSettings] = useState<StreamDevicesSettings>();

  return (
    <BroadcastContext.Provider value={{ deviceSettings, setDeviceSettings }}>
      {children || <Outlet />}
    </BroadcastContext.Provider>
  );
};
