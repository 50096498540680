import { Nullable } from "src/shared/utils";
import { SystemMetadata } from "./system-metadata.types";

export enum MessageVariant {
  USER = "user",
  SYSTEM = "system",
}

export enum ChatVariant {
  PRIVATE = "private",
  GROUP = "group",
  SUPPORT = "support",
}

export type MessageUserDTO = {
  data: {
    user_avatar: string;
    name: string;
  };
  id: string;
};

export type ChatMessageDTO = {
  chat_id: string;
  datetime: string;
  id: string;
  system_metadata: Nullable<SystemMetadata>;
  text?: string;
  type: MessageVariant;
  user: Nullable<MessageUserDTO>;
};

interface MessageUserDTOUpdated extends Omit<MessageUserDTO, "id"> {
  user_id: string;
}

export type ChatMessageDTOUserUpdated = {
  chat_id: string;
  datetime: Date;
  id: string;
  system_metadata: Nullable<SystemMetadata>;
  text?: string;
  type: MessageVariant;
  user: Nullable<MessageUserDTOUpdated>;
};

export type ChatDTO = {
  id: string;
  type: ChatVariant;
  system: boolean;
  title: string;
  avatar_url: string;
  chat_id: string;
  unread_messages_count: number;
  last_message: Nullable<ChatMessageDTO>;
};

export type ChatMemberDTO = {
  id: string;
  data: {
    name: string;
    user_avatar: Nullable<string>;
  };
};
