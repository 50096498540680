// FIX_ME:
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unused-prop-types */
import { FormikProps } from "formik/dist/types";
import { useEffect } from "react";
import { Button, Checkbox, Text } from "@evvve/ui-kit";
import { useOutletContext } from "react-router-dom";
import { LoaderWrap } from "../../../../../shared-features/LoaderWrap/LoaderWrap";
import { ProfileType } from "./ProfileType";
import { IPrivacySettingsForm } from "./PrivacySettings";
import style from "./style.module.scss";
import { IOutletContext } from "../../service/types";
import Honeycomb from "../../../../../shared-features/Honeycomb/Honeycomb";

const {
  wrap,
  title,
  content,
  form,
  description,
  profile_types,
  submit_button,
  hr_bottom,
  form_width,
} = style;

interface PrivacySettingsFormProps extends FormikProps<IPrivacySettingsForm> {
  setDirty?: (dirty: boolean) => void;
  isGetUserPrivacyLoading: boolean;
  isPatchUserPrivacyLoading: boolean;
  // initialFormState: IPrivacySettingsForm;
}

export const PrivacySettingsForm = ({
  handleSubmit,
  handleChange,
  setFieldValue,
  values,
  dirty,
  setDirty,
  isGetUserPrivacyLoading,
  isPatchUserPrivacyLoading,
}: PrivacySettingsFormProps) => {
  const OutletObject = useOutletContext<IOutletContext>();

  useEffect(() => {
    // mobile
    if (setDirty) {
      setDirty(dirty);
    }
    // desktop
    if (OutletObject?.dirtyCallback) {
      OutletObject.dirtyCallback(dirty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  return (
    <form onSubmit={(e) => handleSubmit(e)} className={form_width}>
      <Honeycomb type="a" className={wrap}>
        <div className={title}>
          <Text size={[6, 6, 5]} font="inter" bold>
            {" "}
            Privacy
          </Text>
        </div>
        {isGetUserPrivacyLoading ? (
          <LoaderWrap />
        ) : (
          <div className={content}>
            <div className={description}>
              <Text size="m">
                Our platform supports networking and transparency. But you decide
                what type of profile you want and what information to share with
                others. Your profile photo and full name always stay visible.
              </Text>
            </div>
            <div className={form}>
              <div
                role="group"
                aria-labelledby="my-radio-group"
                className={profile_types}
              >
                <ProfileType
                  title="Public"
                  description={
                    "My profile info and events are visible" +
                    " to those I choose below"
                  }
                  value="public"
                  name="profileType"
                  onChange={(e) => {
                    setFieldValue("profileType", e.currentTarget.value);
                  }}
                />
                <ProfileType
                  title="Private"
                  description="Nobody can see my profile info and events"
                  value="private"
                  name="profileType"
                  onChange={(e) => {
                    setFieldValue("profileType", e.currentTarget.value);
                  }}
                />
              </div>
              {/* <div */}
              {/*  className={values.profileType === "public" ? */}
              {/*    visibility : display_none}> */}
              {/*  <Text size={"m"}>Show events where I’m:</Text> */}
              {/*  <div role="group" aria-labelledby="checkbox-group" className={group}> */}
              {/*    <Checkbox label="Organizer" */}
              {/*      name="showEventsRoles" */}
              {/*      size="m" */}
              {/*      value={"organizer"} */}
              {/*      checked={arrayIncludes(values.showEventsRoles, "organizer")} */}
              {/*      onChange={handleChange} */}
              {/*    /> */}
              {/*    <Checkbox label="Speaker" */}
              {/*      name="showEventsRoles" */}
              {/*      size="m" */}
              {/*      value={"speaker"} */}
              {/*      checked={arrayIncludes(values.showEventsRoles, "speaker")} */}
              {/*      onChange={handleChange} */}
              {/*    /> */}
              {/*    <Checkbox label="Attendee" */}
              {/*      name="showEventsRoles" */}
              {/*      size="m" */}
              {/*      value={"attendee"} */}
              {/*      checked={arrayIncludes(values.showEventsRoles, "attendee")} */}
              {/*      onChange={handleChange} */}
              {/*    /> */}
              {/*  </div> */}
              {/* </div> */}
              <hr className={`${hr_bottom} solid`} />
              <Checkbox
                name="startChat"
                label="Allow other users to start chat dialogs with me"
                checkboxSize="m"
                checked={values.startChat}
                onChange={handleChange}
              />
            </div>
            <Button
              id="save-changes-button"
              size="m"
              theme="violet"
              type="solid"
              className={submit_button}
              disabled={isPatchUserPrivacyLoading}
              htmlType="submit"
            >
              Save changes
            </Button>
          </div>
        )}
      </Honeycomb>
    </form>
  );
};
