// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { messengerApi } from "../..";
import { ChatMessageDTOExtended } from "../../types";

type Args = {
  chatId: string;
  newMessage: ChatMessageDTOExtended;
};

export const updateMessageListOnReceiveNewMessage = ({ chatId, newMessage }: Args) =>
  messengerApi.util.updateQueryData("getMessagesHistory", { chatId }, (draft) => {
    draft.data.push(newMessage);
  });
