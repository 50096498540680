// FIX_ME:
/* eslint-disable @typescript-eslint/no-use-before-define */
import topLevelDomains from "./top-level-domains.json";

const topLevelDomainListRegexStr = `(${topLevelDomains.join("|")})`;

export function isValidHttpUrl(urlString: string) {
  try {
    const url = new URL(urlString);

    return url.protocol === "http:" || url.protocol === "https:";
  } catch (err) {
    return false;
  }
}

export function hasURLIncludeSubdomain(url: string) {
  const subDomain = !!url.match(/\./g);

  return subDomain;
}

export function removeURLProtocolAndSubdomains(url: string) {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
}

const urlRegExProtocolPart = "((https?|ftp):\u005c\u002f\u005c\u002f)?";
const urlDomainSeriesRegExPart = `(([a-zA-Z0-9+-]+)\u005c\u002e)+${topLevelDomainListRegexStr}`;
const urlPortRegExPart = "(:[0-9]+)?";
const urlPathRegExPart = "(\u005c\u002f[a-zA-Z0-9-._~%\u005c\u002f]*)?";
const urlQueryParameterListRegExPart = "(\u005c\u003f[a-zA-Z0-9-._~&=]*)?";
const urlFragmentIdentifier = "(#.*)?";

const urlRegExTemplate =
  urlRegExProtocolPart +
  urlDomainSeriesRegExPart +
  urlPortRegExPart +
  urlPathRegExPart +
  urlQueryParameterListRegExPart +
  urlFragmentIdentifier;

const urlRegEx = new RegExp(urlRegExTemplate, "");
const protocolRegEx = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)/;

export function findFirstUrlSubstring(text: string) {
  if (!text) return null;

  const urlMatch = text.match(urlRegEx);

  if (!urlMatch || urlMatch.index === undefined) return null;

  const urlSubstring = urlMatch[0];
  const urlSubstringStartIndex = urlMatch.index;
  const urlSubstringLength = urlSubstring.length;

  return {
    urlSubstring,
    urlSubstringStartIndex,
    urlSubstringLength,
    // FIX_ME:
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    href: recoverProtocolInUrl(urlSubstring),
  };
}

export function recoverProtocolInUrl(url: string) {
  return url.match(protocolRegEx) ? url : `https://${url}`;
}
