// FIX_ME:
/* eslint-disable import/no-cycle */
import {
  FC,
  useCallback,
  UIEvent,
  useRef,
  useEffect,
  useMemo,
  useLayoutEffect,
} from "react";
import { ChatDTO, ChatMessageDTOExtended, messengerApi } from "src/store/messenger";
import { Nullable } from "src/shared/utils";
import { useAppSelector } from "src/hooks/useStoreHooks";
import { Spinner } from "@evvve/ui-kit";
import { MessageItem } from "../message-item";
import s from "./styles.module.scss";
import { getMessagesWithDateDividers } from "./helpers";
import { DateSeparator } from "../date-separator";

type Props = {
  chatInfo: ChatDTO;
  messageList: ChatMessageDTOExtended[];
  loadMore: () => Promise<unknown>;
  isLoading: boolean;
};

const MessageListWithSeparators: FC<Props> = ({
  messageList,
  chatInfo,
  loadMore,
  isLoading,
}) => {
  const messageListContainerRef = useRef<Nullable<HTMLDivElement>>(null);

  const messagesWithDividers = useMemo(
    () => getMessagesWithDateDividers(messageList),
    [messageList],
  );

  const [sendLastReadMessageIdMutation] =
    messengerApi.useSendLastReadMessageMutation();

  const balanceScrollPosition = useCallback(() => {
    const [topMessage] = messageList;

    const topMessageNode = document.getElementById(topMessage.id);

    topMessageNode?.scrollIntoView({ behavior: "auto" });
  }, [messageList]);

  // TODO: добавить троттлинг
  const handleScroll = useCallback(
    (event: UIEvent<HTMLElement>) => {
      const isScrollAtTop = event.currentTarget.scrollTop === 0;

      if (isScrollAtTop) {
        loadMore().then(balanceScrollPosition);
      }
    },
    [balanceScrollPosition, loadMore],
  );

  const me = useAppSelector((state) => state.user.userInfo);

  const lastMessage = useMemo(() => {
    if (!messageList || !messageList.length) return null;

    const lastIndex = messageList.length - 1;

    return messageList[lastIndex];
  }, [messageList]);

  const amITheSenderOfLastMessage = lastMessage?.user?.id === me?.unique_id;

  const readAllMessages = useCallback(() => {
    const areAllMessagesRead = !lastMessage || !lastMessage.isUnread;
    if (areAllMessagesRead) return;
    sendLastReadMessageIdMutation({
      messageId: lastMessage.id,
      chatId: lastMessage.chat_id,
    });
  }, [lastMessage, sendLastReadMessageIdMutation]);

  const onBottomReached = useCallback(() => {
    readAllMessages();
  }, [readAllMessages]);

  const scrollToBottom = () => {
    if (messageListContainerRef.current) {
      const element = messageListContainerRef.current;
      const start = element.scrollTop;
      const end = element.scrollHeight;
      const duration = 200;
      let startTime: number | null = null;

      const smoothScroll = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        element.scrollTop = start + (end - start) * progress;

        if (timeElapsed < duration) {
          requestAnimationFrame(smoothScroll);
        }
      };

      requestAnimationFrame(smoothScroll);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [amITheSenderOfLastMessage, lastMessage?.id]);

  useLayoutEffect(() => {
    if (isLoading) {
      messageListContainerRef.current?.classList.add(s.isBlockScroll);
    } else {
      messageListContainerRef.current?.classList.remove(s.isBlockScroll);
    }
  }, [isLoading]);

  useEffect(() => {
    onBottomReached();
  }, [onBottomReached]);

  return (
    <div
      className={s.messageList}
      onScroll={handleScroll}
      ref={messageListContainerRef}
    >
      {isLoading && (
        <div className={s.spinnerWrapper}>
          <Spinner size="s" />
        </div>
      )}
      <div className={s.messageListInner}>
        {messagesWithDividers.map((messageOrDivider, index) => {
          if (typeof messageOrDivider === "string") {
            return <DateSeparator key={messageOrDivider} text={messageOrDivider} />;
          }

          const currentMessage = messageOrDivider as ChatMessageDTOExtended;

          const nextItem = messagesWithDividers[index + 1];
          // NOTE: если это разделитель, то использует следующее сообщение как next one
          const shouldSkipNextItem = typeof nextItem === "string";

          const nextMessage = shouldSkipNextItem
            ? messagesWithDividers[index + 2]
            : nextItem;

          return (
            <MessageItem
              key={currentMessage.id}
              nextMessage={nextMessage as ChatMessageDTOExtended}
              message={currentMessage}
              chatVariant={chatInfo?.type}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MessageListWithSeparators;
