import React, { useState, useEffect } from "react";
import { ModalTemplate } from "src/close-part/features/Modals";
import { Button, Text } from "@evvve/ui-kit";
import { Location, NavigateFunction } from "react-router-dom";
import style from "./style.module.scss";

type Props = {
  openElement?: React.ReactNode;
  isOpen?: boolean;
  title: string;
  caption: string;
  onClose?: () => void;
  navigate?: NavigateFunction;
  fromLocation?: Location;
};

const LogInModal: React.FC<Props> = ({
  openElement,
  isOpen,
  title,
  caption,
  onClose,
  navigate,
  fromLocation,
}) => {
  const [isOpenOwn, setIsOpenOwn] = useState(false);
  useEffect(() => setIsOpenOwn(isOpen ?? false), [isOpen]);

  return (
    <ModalTemplate
      isOpen={isOpenOwn}
      onClose={onClose ?? (() => setIsOpenOwn(false))}
      className={style.modalLogInWrap}
      OpenElement={openElement}
    >
      <div className={style.title}>
        <Text size={[6, 6, 4]} bold>
          {title}
        </Text>
      </div>
      <div className={style.description}>
        <Text size="m">{caption}</Text>
      </div>
      <div className={style.buttons}>
        <Button
          size="m"
          type="solid"
          onClick={
            navigate &&
            fromLocation &&
            (() => navigate("/login", { state: { from: fromLocation } }))
          }
        >
          Log in or sign up
        </Button>
        <Button
          size="m"
          type="outline"
          onClick={onClose ?? (() => setIsOpenOwn(false))}
        >
          Cancel
        </Button>
      </div>
    </ModalTemplate>
  );
};
export default LogInModal;
