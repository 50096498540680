import React, { createContext, PropsWithChildren, useEffect } from "react";
import { io } from "socket.io-client";
import { Outlet } from "react-router-dom";
import { baseMessengerApi } from "src/store/messenger/api";
import { CHAT_WEB_SOCKET_URL } from "src/constants";

type ChatSocketConnection =
  | { chatSocket: undefined | ReturnType<typeof io> | null; connecting: boolean }
  | null
  | undefined;

export const ChatSocketIoContext = createContext<ChatSocketConnection>(undefined);

//
export const ChatWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const [getChatAuthToken, { data: chatAuthOption, isLoading, isError }] =
    baseMessengerApi.useGetChatAuthTokenMutation();

  const chatToken = chatAuthOption?.token || localStorage.getItem("chat-token");

  useEffect(() => {
    getChatAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (!isUninitialized) return

    if (!isLoading && !isError && chatAuthOption && CHAT_WEB_SOCKET_URL) {
      localStorage.setItem("chat-token", chatAuthOption.token);
      localStorage.setItem("chat-refresh-token", chatAuthOption.refresh_token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return chatToken && children ? <>{children}</> : <Outlet />;
};
