// FIX_ME:
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { Nullable } from "src/shared/utils";
import { messengerApi } from "../../api";

type Args = {
  chatId: string;
  lastEvaluatedKey: Nullable<unknown>;
};

// [CHAT] TODO: [BE] не присылает событие чата, когда собеседник удаляет чат
// на это событие приходит UPDATE_DIALOG а должен DELETE_CHAT После релиза
export const updateChatListOnDeleteChat = ({ chatId, lastEvaluatedKey }: Args) =>
  messengerApi.util.updateQueryData("getChatList", { lastEvaluatedKey }, (draft) => {
    const chatList = draft.data;

    const updatedChatList = chatList.filter((chat) => chat.chat_id !== chatId);

    draft.data = updatedChatList;
  });
