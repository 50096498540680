// FIX_ME:
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import moment, { Moment } from "moment/moment";
import { utc } from "moment";

const ONE_MINUTE_IM_MS = 60000;

export const calcMinutes = (start: number, finish: number): number =>
  (finish - start) / ONE_MINUTE_IM_MS;

export const changeTimeFormat = (date: string | Date | Moment) =>
  buildDateTime(date)
    .format("h:mm a")
    .replace("pm", "p.m.")
    .replace("am", "a.m.")
    .replace(" ", "\u00A0");

export const getTimeIn12hFormat = changeTimeFormat;

export const isInRange = (
  time: Date | number,
  start: Date | number,
  end: Date | number,
) => start <= time && time <= end;
export const isAfterRange = (time: Date | number, end: Date | number) => time >= end;

export function changeDateFormat(date: string | Date | Moment, tzOffset?: number) {
  return buildDateTime(date, tzOffset)?.format("ll");
} // format Jan 23, 2023

export const buildDateTime = (
  dateTime: Date | Moment | string,
  tzOffset?: number,
  date?: Date | Moment | string,
  isTimeShiftNeeded?: boolean,
) => {
  let result;
  if (Number.isInteger(tzOffset)) {
    result = moment(dateTime)
      .utcOffset(tzOffset || 0, !isTimeShiftNeeded)
      .seconds(0)
      .milliseconds(0);
  } else {
    // render local time in open part
    result = moment.utc(dateTime).local();
  }
  if (date) {
    const correctDate = moment(date);
    result
      .year(correctDate.year())
      .month(correctDate.month())
      .date(correctDate.date());
  }

  return result;
};

/// ///////
export const convertSelectedDateToMoment = (
  eventTzOffset: number,
  dateWithoutTime: Date,
) => moment(dateWithoutTime).utcOffset(eventTzOffset, true); // change tz without date/time recalculation

export const convertSelectedTimeToMoment = (
  eventTzOffset: number,
  dateWithTime: Date | string,
) => moment(dateWithTime).seconds(0).milliseconds(0).utcOffset(eventTzOffset, true); // change tz and recalculate date/time

// update datetimes when new tz selected
export const updateTimezone = (eventTzOffset: number, moment: Moment) =>
  moment.clone().utcOffset(eventTzOffset, true);

// arguments have correct tz date time
export const replaceDate = (val: Moment, targetDate: Moment) => {
  const result = val.clone();

  return result
    .year(targetDate.year())
    .month(targetDate.month())
    .date(targetDate.date());
};

export const calcEndTime = (val: Moment, eventStart: Moment) => {
  const end = replaceDate(val, eventStart); // cloned
  if (end < eventStart) {
    end.add(1, "days");
  }

  return end;
};

export const calcMinEventStartDate = (startTime: Moment, nowInEventTz: Moment) =>
  startTime > nowInEventTz // if startTime set, it has correct time and tz
    ? nowInEventTz // today
    : startTime.clone().add(1, "days");

// use local tz to set to a date/time-picker (to render)
export const convertMomentToDate = (moment?: Moment) => {
  if (!moment) return undefined;

  return moment.clone().local(true).toDate();
};

// response -> form model
export const convertUtcToMoment = (eventTzOffset: number, utcValue?: string) =>
  utcValue ? utc(utcValue).utcOffset(eventTzOffset) : undefined;

// preview (create step 3), landing, lobby
export const convertUtcToLocal = (utcValue: string) => utc(utcValue).local();

// form model -> request
export const convertMomentToUtc = (value?: Moment) =>
  value ? utc(value).format() : "";

export const formatTime = (time: Moment) =>
  time
    .format("h:mm a")
    .replace("pm", "p.m.")
    .replace("am", "a.m.")
    .replace(" ", "\u00A0");

export const formatDate = (date: Moment) => date.format("ll");
