import { useRef } from "react";
import { messengerApi } from "src/store/messenger";
import { baseMessengerApi } from "src/store/messenger/api";

export const useInitPrivateChatComplex = () => {
  const isExistedChat = useRef(false);

  const [getChatInfoByUserQuery, getChatInfoByUserState] =
    messengerApi.useLazyGetChatInfoByUserQuery();

  const [initDialogQuery, initDialogState] =
    baseMessengerApi.useLazyInitDialogQuery();

  const makeComplexRequest = async ({ userId }: { userId: string }) => {
    const chatByUser = await getChatInfoByUserQuery({ userId });

    if (chatByUser.status === "rejected") {
      isExistedChat.current = false;
      const initedDialog = await initDialogQuery({ userId });

      if (initedDialog.isError) {
        // [CHAT] TODO: правильно обработать (выяснить, что должно происходить если на инит мы получили ошибку) После релиза
        console.error(initedDialog.error);
      } else {
        return initedDialog;
      }
    } else {
      isExistedChat.current = true;

      return chatByUser;
    }
  };

  return {
    initChatHandler: makeComplexRequest,
    isLoading: getChatInfoByUserState.isLoading || initDialogState.isLoading,
  };
};
