import { Link as RouterLink } from "react-router-dom";
import React from "react";
import s from "./style.module.scss";

interface LinkProps {
  target: string;
  to: any;
  prevLocation: string;
  src: string;
  alt?: string;
  className?: string;
}
const ImageLink = ({
  src,
  target,
  to,
  prevLocation,
  alt = "",
  className,
}: LinkProps) => (
  <RouterLink
    rel="noreferrer"
    to={to}
    target={target}
    state={{ prevPath: prevLocation }}
    className={s.imageLink}
  >
    <img src={src} alt={alt} className={className} />
  </RouterLink>
);
export default ImageLink;
