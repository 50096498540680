import React, { FC } from "react";

import { Icons, Text } from "@evvve/ui-kit";

import { TheEventHasBeenCancelledMetadata } from "src/store/messenger";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: TheEventHasBeenCancelledMetadata;
};

const EventHasBeenCancelled: FC<Props> = ({ metadata }) => (
  <NotificationTemplate>
    <div className={styles.info}>
      <Icons.Info color="tangerine1000" size="s" />
      <Text size="m" color="tangerine1000">
        The event has been cancelled
      </Text>
    </div>
    <Text size="m" as="p">
      {metadata.data.event_name}
    </Text>
  </NotificationTemplate>
);

export default EventHasBeenCancelled;
