import React, { ReactElement } from "react";
import { Link, To } from "react-router-dom";
import { Logo } from "@evvve/ui-kit";

interface Props {
  path: To;
  theme: "dark" | "light";
}
const LogoLink = ({ path, theme }: Props): ReactElement => (
  <Link to={path} style={{ display: "block", fontSize: "0px" }}>
    <Logo theme={theme} />
  </Link>
);

export default LogoLink;
