import { Icons } from "@evvve/ui-kit";
import React, { FC } from "react";

import { MessageSendingStatus } from "src/store/messenger";
import s from "./styles.module.scss";

type Props = {
  state: MessageSendingStatus;
};

const sendingStateToIcon = {
  [MessageSendingStatus.SENDING]: <Icons.Clock color="grayscale400" size="s" />,
  [MessageSendingStatus.SENT]: <Icons.Check color="grayscale400" size="s" />,
  [MessageSendingStatus.FAILED]: <Icons.AlertCircle color="grayscale400" size="s" />,
};

const SendingState: FC<Props> = ({ state }) => (
  <div className={s.messageStatus}>{sendingStateToIcon[state]}</div>
);

export default SendingState;
