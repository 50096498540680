// FIX_ME:
/* eslint-disable no-param-reassign */
/* eslint-disable no-void */
/* eslint-disable import/no-cycle */
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query";
import { appApi } from "../../api/api";
import {
  DefaultResponse,
  EventPayload,
  EventResponse,
  EventStatus,
  JoinEventResponse,
  MemberConfirmResponse,
  SessionResponse,
  SpeakerResponse,
  SpeakersResponse,
  EventMemberRoleAndStatus,
  MyEventResponseModel,
  EventInvitationRequest,
  GetEventMembersByIdItem,
  EventInitResponse,
  EventAnalyticsResponse,
  SessionPayload,
  EventInitResponseRaw,
  EventCatalogSorting,
  EventMemberRole,
  OrganizedEventsAnalyticsResponse,
  MyOrganizedEventsAnalyticsArg,
  EventType,
  Nullable,
  InviteSpeakerResp,
  InviteSpeakerQuery,
} from "./types";
import { PageResponse } from "../store";

type EventIdApiArg = {
  eventId: string;
};

type UserIdApiArg = {
  userId: string;
};

type RoleApiArg = {
  role: EventMemberRole;
};

type PageApiArg = {
  page: number;
  size?: number;
};

type ReqBodyArg<T> = {
  reqBody: T;
};

type GetEventMembersByIdArg = EventIdApiArg &
  Partial<UserIdApiArg> &
  Partial<RoleApiArg> &
  Partial<PageApiArg>;

type GetEventInitInfoQueryArg = EventIdApiArg;

type JoinEventApiArg = EventIdApiArg;

type MemberConfirmApiArg = EventIdApiArg & ReqBodyArg<EventMemberRoleAndStatus>;

type EventStatusApiArg = {
  status: EventStatus;
};

type UpdateEventStatusApiArg = EventIdApiArg & ReqBodyArg<EventStatusApiArg>;

type DeleteSpeakerQuery = {
  eventId: string;
  payload: {
    session_id: string;
    user_id?: string;
    invitation_id?: Nullable<string>;
  };
};

const transformSpeakers = (speakers: SpeakersResponse): SpeakerResponse[] => {
  const members: SpeakerResponse[] = speakers.members.map((member) => ({ member }));
  const invitations: SpeakerResponse[] = speakers.invitations.map((invitation) => ({
    invitation,
  }));

  return members.concat(invitations);
};

const eventsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserEvents: builder.query<
      PageResponse<MyEventResponseModel>,
      {
        userId: string;
        page: number;
        size: number;
        sortBy?: string;
        status?: string[];
        roles: string[];
        name?: string;
      }
    >({
      query: ({ page, size = 50, sortBy, status, roles, name, userId }) => {
        const statusParams = status
          ? status.map((s) => `status=${s}`).join("&")
          : "";
        const rolesParams = roles.map((r) => `roles=${r}`).join("&");
        const nameParam = name ? `&name=${name}` : "";
        const sortByParam = sortBy ? `&sortBy=${sortBy}` : "";

        return `/events/users?page=${page}&size=${size}&${statusParams}&${rolesParams}${sortByParam}${nameParam}&user_id=${userId}`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs: {
            roles: queryArgs.roles,
            status: queryArgs.status,
            userId: queryArgs.userId,
          },
          endpointDefinition,
          endpointName,
        }),
      merge(
        currentCacheData: PageResponse<MyEventResponseModel>,
        responseData: PageResponse<MyEventResponseModel>,
      ): void | PageResponse<MyEventResponseModel> {
        if (responseData.page > currentCacheData.page) {
          currentCacheData.items.push(...responseData.items);
        } else {
          currentCacheData.items = responseData.items;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.items.map(({ unique_id }) => ({
                type: "UserEvents" as const,
                id: unique_id,
              })),
              { type: "UserEvents", id: arg.userId },
              { type: "UserEvents", id: "LIST" },
            ]
          : [{ type: "UserEvents", id: "LIST" }],
    }),
    getEvents: builder.query<
      PageResponse<EventResponse>,
      {
        category?: string;
        page: number;
        size: number;
        sortBy?: EventCatalogSorting | string;
        name?: string;
      }
    >({
      query: ({ page, size = 5, sortBy, name }) => {
        const nameParam = name ? `&name=${name}` : "";
        const sortByParam = sortBy ? `&sort_by=${sortBy}` : "";

        return `/events?page=${page}&size=${size}${sortByParam}${nameParam}`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs: {
            sortBy: queryArgs.sortBy,
          },
          endpointDefinition,
          endpointName,
        }),
      merge(
        currentCacheData: PageResponse<MyEventResponseModel>,
        responseData: PageResponse<MyEventResponseModel>,
      ): void | PageResponse<MyEventResponseModel> {
        if (responseData.page > currentCacheData.page) {
          currentCacheData.items.push(...responseData.items);
        } else {
          currentCacheData.items = responseData.items;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ unique_id }) => ({
                type: "UserEvents" as const,
                id: unique_id,
              })),
              { type: "UserEvents", id: "LIST" },
            ]
          : [{ type: "UserEvents", id: "LIST" }],
    }),
    getEvent: builder.query<EventResponse, string>({
      query: (eventId: string) => `/events/${eventId}`,
    }),

    createSimpleEvent: builder.mutation({
      query: (event: EventPayload) => ({
        url: "/events/",
        method: "POST",
        body: event,
      }),
      invalidatesTags: [{ type: "UserEvents", id: "LIST" }],
    }),

    updateSimpleEvent: builder.mutation({
      query: (event: EventPayload) => ({
        url: `/events/${event.unique_id}`,
        method: "PUT",
        body: event,
      }),
    }),
    getEventMembersById: builder.query<
      PageResponse<GetEventMembersByIdItem>,
      GetEventMembersByIdArg
    >({
      query: ({ eventId, userId, role, page, size = 50 }) => ({
        url: `/events/${eventId}/members`,
        params: {
          user_id: userId,
          role,
          page,
          size,
        },
      }),
    }),

    getEventInitInfo: builder.query<EventInitResponse, GetEventInitInfoQueryArg>({
      query: ({ eventId }) => `/events/${eventId}/init`,
      transformResponse: (response: EventInitResponseRaw) => ({
        ...response,
        speakers: transformSpeakers(response.speakers),
      }),
      providesTags: (...args) => {
        const [result, , { eventId }] = args;

        return result?.current_user_as_member
          ? [
              {
                type: "UserEvents",
                id: result.current_user_as_member.user.unique_id,
              },
              {
                type: "CurrentEvent",
                id: eventId,
              },
            ]
          : [
              { type: "UserEvents", id: "LIST" },
              {
                type: "CurrentEvent",
                id: eventId,
              },
            ];
      },
    }),

    joinEvent: builder.mutation<JoinEventResponse, JoinEventApiArg>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/join`,
        method: "POST",
      }),
      invalidatesTags: (...args) => {
        const [, , { eventId }] = args;

        return [
          {
            type: "UserEvents",
            id: eventId,
          },
          { type: "UserEvents", id: "LIST" },
          { type: "CurrentEvent", id: eventId },
        ];
      },
    }),

    confirmRoleOnEvent: builder.mutation<MemberConfirmResponse, MemberConfirmApiArg>(
      {
        query: ({ eventId, reqBody }) => ({
          url: `/events/${eventId}/member-confirm`,
          method: "POST",
          body: reqBody,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "UserEvents", id: arg.eventId },
          { type: "CurrentEvent", id: arg.eventId },
        ],
      },
    ),

    updateEventStatus: builder.mutation<EventResponse, UpdateEventStatusApiArg>({
      query: ({ eventId, reqBody }) => ({
        url: `/events/${eventId}/set-status`,
        method: "POST",
        body: reqBody,
      }),
    }),

    // speaker START
    // NOTE: deprecated
    sendInvitation: builder.mutation<SpeakerResponse, EventInvitationRequest>({
      query: ({ eventId, ...emailOrUserId }: EventInvitationRequest) => ({
        url: `/events/${eventId}/speakers`,
        method: "POST",
        body: emailOrUserId,
      }),
    }),

    inviteSpeaker: builder.mutation<InviteSpeakerResp, InviteSpeakerQuery>({
      query: ({ eventId, ...newSpekerReqBody }) => ({
        url: `/events/${eventId}/speaker`,
        method: "POST",
        body: newSpekerReqBody,
      }),
      invalidatesTags: (_, __, args) => [{ type: "SpeakerList", id: args.eventId }],
    }),

    resendInvitation: builder.mutation<DefaultResponse, string>({
      query: (invitationId: string) => ({
        url: `/events/resend-invitation/${invitationId}`,
        method: "POST",
      }),
      // TODO: invalidateTags
    }),

    getSpeakers: builder.query<SpeakerResponse[], string>({
      query: (eventId: string) => `/events/${eventId}/speakers`,
      transformResponse: (response: SpeakersResponse) => transformSpeakers(response),
      providesTags: (result, error, eventId) => [
        { type: "SpeakerList", id: eventId },
      ],
    }),

    deleteSpeaker: builder.mutation<unknown, DeleteSpeakerQuery>({
      query: ({ eventId, payload }) => ({
        url: `/events/${eventId}/speaker`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: (result, error, args) => [
        { type: "SpeakerList", id: args.eventId },
      ],
    }),
    // speaker END

    getSessions: builder.query<SessionResponse[], string>({
      query: (eventId: string) => `/events/${eventId}/sessions`,
      transformResponse: (response: SessionResponse[]) =>
        response.map((session, index) => {
          session.sequence_number = index + 1;

          return session;
        }),
      providesTags: (result, error, eventId) => [
        { type: "SessionList", id: eventId },
      ],
    }),
    createSession: builder.mutation<SessionResponse, SessionPayload>({
      query: (session: SessionPayload) => ({
        url: "/events/session",
        method: "POST",
        body: session,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SessionList", id: arg.event_id },
        { type: "SpeakerList", id: arg.event_id },
      ],
    }),
    updateSessions: builder.mutation<SessionResponse[], SessionPayload[]>({
      query: (sessions: SessionPayload[]) => ({
        url: "/events/sessions",
        method: "PUT",
        body: { items: sessions },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SessionList", id: arg[0].event_id },
        { type: "SpeakerList", id: arg[0].event_id },
        { type: "CurrentEvent", id: arg[0].event_id },
      ],
    }),
    deleteSessions: builder.mutation({
      query: ({ sessionIds }) => ({
        url: "/events/sessions",
        method: "DELETE",
        body: sessionIds,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SessionList", id: arg.eventId },
        { type: "SpeakerList", id: arg.eventId },
        { type: "CurrentEvent", id: arg.eventId },
      ],
    }),
    clearComplexEvent: builder.mutation({
      query: (eventId: string) => ({
        url: `/events/${eventId}/clear`,
        method: "POST",
      }),
    }),
    changeEventType: builder.mutation({
      query: ({
        eventId,
        eventType,
      }: {
        eventId: string;
        eventType: EventType;
      }) => ({
        url: `/events/${eventId}/change-type`,
        method: "POST",
        body: { type: eventType },
      }),
    }),
    setEventStatus: builder.mutation({
      query: ({ eventId, status }: { eventId: string; status: EventStatus }) => ({
        url: `/events/${eventId}/set-status`,
        method: "POST",
        body: {
          status: status.toLowerCase(),
        },
      }),
    }),
    deleteEvent: builder.mutation({
      query: (eventId: string) => ({
        url: `/events/${eventId}`,
        method: "DELETE",
      }),
      // fix bug PROD-1637
      // invalidatesTags: (result, error, arg) => [
      //   { type: "UserEvents", id: arg },
      //   { type: "UserEvents", id: "LIST" },
      // ],
      async onQueryStarted(eventId, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const eventListQueries = appApi.util.selectInvalidatedBy(getState(), [
            { type: "UserEvents", id: "LIST" },
          ]);
          eventListQueries.forEach((q) =>
            dispatch(
              eventsApi.util.updateQueryData(
                "getUserEvents",
                q.originalArgs,
                (draft) => {
                  const { items } = draft;
                  const filteredItems = items.filter(
                    (item) => item.unique_id !== eventId,
                  );
                  Object.assign(draft, {
                    ...draft,
                    items: filteredItems,
                    total: draft.total === 0 ? draft.total : draft.total - 1,
                  });
                },
              ),
            ),
          );
        } catch {
          void 0;
        }
      },
    }),

    getOrganizedEventsAnalytics: builder.query<
      OrganizedEventsAnalyticsResponse,
      MyOrganizedEventsAnalyticsArg
    >({
      query: ({ page, size = 50 }) => ({
        url: "/users/me/organized-events-analytics",
        params: {
          page,
          size,
        },
      }),
      providesTags: [{ type: "UserEvents", id: "LIST" }],
    }),

    getEventAnalytics: builder.query<EventAnalyticsResponse, EventIdApiArg>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/analytics`,
      }),
    }),

    getMyInvites: builder.query({
      query: () => "/events/my/invites",
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserEventsQuery,
  useGetEventQuery,
  useCreateSimpleEventMutation,
  useUpdateSimpleEventMutation,
  useGetEventMembersByIdQuery,
  useGetEventInitInfoQuery,
  useJoinEventMutation,
  useConfirmRoleOnEventMutation,
  useUpdateEventStatusMutation,
  useGetSpeakersQuery,
  useDeleteSpeakerMutation,
  useResendInvitationMutation,
  useGetSessionsQuery,
  useDeleteSessionsMutation,
  useUpdateSessionsMutation,
  useClearComplexEventMutation,
  useSetEventStatusMutation,
  useDeleteEventMutation,
  useGetEventsQuery,
  useGetEventAnalyticsQuery,
  useLazyGetEventAnalyticsQuery,
  useGetOrganizedEventsAnalyticsQuery,
  useLazyGetOrganizedEventsAnalyticsQuery,
  useChangeEventTypeMutation,
} = eventsApi;

export { eventsApi };
