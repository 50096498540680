import { FC, useEffect } from "react";
import { ChatDTO } from "src/store/messenger";
import {
  useMessengerViewController,
  usePullMessageList,
} from "src/close-part/features/Messenger/hooks";
import { MessageListPlaceholder } from "../message-list-placeholder";
import { MessageListWithSeparators } from "../message-list-with-separators";
import ChatSpinner from "../../../chat-spinner/ChatSpinner";

type Props = {
  chatInfo?: ChatDTO;
};

const MessageList: FC<Props> = ({ chatInfo }) => {
  const { chatId } = useMessengerViewController();

  const {
    messageList,
    initialPartIsLoading,
    getInitialMessageList,
    initialPartIsUninitialized,
    nextPartIsLoading,
    onLoadMoreMessages,
  } = usePullMessageList(chatId);

  useEffect(() => {
    if (initialPartIsUninitialized) getInitialMessageList();
  }, [initialPartIsUninitialized, getInitialMessageList]);

  if (initialPartIsLoading) {
    return <ChatSpinner />;
  }

  if (!messageList.length) {
    return <MessageListPlaceholder chatVariant={chatInfo?.type} />;
  }

  return chatInfo ? (
    <MessageListWithSeparators
      chatInfo={chatInfo}
      messageList={messageList}
      loadMore={onLoadMoreMessages}
      isLoading={nextPartIsLoading}
    />
  ) : null;
};

export default MessageList;
