// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { Icons, Text } from "@evvve/ui-kit";
import { noop } from "lodash";
import cn from "classnames";
import style from "./style.module.scss";
import ModalBack from "../ModalBack/index";

const { X } = Icons;

export interface ModalControlProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface ModalTemplateProps extends ModalControlProps {
  children?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  buttons?: React.ReactNode;
  OpenElement?: React.ReactNode;
  className?: string;
  parentEl?: HTMLElement;
  closable?: boolean;
  desktopLayoutOnly?: boolean;
  resetDefaultPosition?: boolean;
  withoutBackground?: boolean;
}

const ModalTemplate = (props: ModalTemplateProps) => {
  const {
    children,
    isOpen,
    onClose,
    OpenElement,
    className,
    buttons,
    title,
    description,
    parentEl,
    closable = true,
    desktopLayoutOnly,
    resetDefaultPosition,
    withoutBackground,
  } = props;

  const { modal, cross_icon, titleStyle, descriptionStyle, buttonsSection } = style;

  const onClickButtonClose = () => {
    onClose();
  };

  return (
    <>
      {OpenElement !== undefined && OpenElement}
      <ModalBack
        isOpen={isOpen}
        onClose={onClose}
        parentEl={parentEl}
        desktopLayoutOnly={desktopLayoutOnly}
        resetDefaultPosition={resetDefaultPosition}
        withoutBackground={withoutBackground}
      >
        <>
          <div
            className={cn(
              {
                [style.desktopOnly]: desktopLayoutOnly,
                [style.defaultPosition]: !resetDefaultPosition,
              },
              modal,
              className,
            )}
          >
            {title && (
              <div className={titleStyle}>
                <Text size={[4, 4, 4]} bold>
                  {title}
                </Text>
              </div>
            )}
            {closable && (
              <div
                onClick={onClickButtonClose}
                className={cross_icon}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
                aria-labelledby="TODO:"
              >
                <X size="m" />
              </div>
            )}
            {description && (
              <div className={descriptionStyle}>
                <Text size="m">{description}</Text>
              </div>
            )}
            {children}
            {buttons && <div className={buttonsSection}>{buttons}</div>}
          </div>
        </>
      </ModalBack>
    </>
  );
};

export default ModalTemplate;
