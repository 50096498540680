import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_API_KEY } from "../constants";

export const initAmplitude = (
  options?: amplitude.Types.BrowserOptions | undefined,
) => {
  if (AMPLITUDE_API_KEY) amplitude.init(AMPLITUDE_API_KEY, options);
};

export const sendAmplitudeData = (
  eventType: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, any>,
) => {
  amplitude.track(eventType, eventProperties);
};
