import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

type PropsType = {
  wrappers: React.ComponentType<PropsWithChildren>[];
};

export const WrappersApply: React.FC<PropsWithChildren<PropsType>> = ({
  wrappers,
  children,
}) =>
  wrappers
    .reverse()
    .reduce(
      (acc, CurrentWrapper) => <CurrentWrapper>{acc}</CurrentWrapper>,
      <>{children || <Outlet />}</>,
    );
