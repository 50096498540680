import React, { PropsWithChildren } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/useStoreHooks";

const RedirectIfUrlUsernameIsNotThisUser = ({ children }: PropsWithChildren) => {
  const { username: accountPageUserUsername } = useParams();
  const thisUserInfo = useAppSelector((state) => state.user.userInfo);
  const thisUserUsername = thisUserInfo?.username;

  if (
    accountPageUserUsername &&
    thisUserUsername &&
    accountPageUserUsername !== thisUserUsername
  )
    return <Navigate to={`/profile/${thisUserUsername}`} />;

  return <>{children}</>;
};

export default RedirectIfUrlUsernameIsNotThisUser;
