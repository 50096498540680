import React from "react";

import { Icons, Text, Avatar } from "@evvve/ui-kit";

import Link from "src/shared-features/Link";
import { useGetUserQuery } from "src/store/User/api";

import cn from "classnames";
import { NewSpeakerAtTheEventMetadata } from "src/store/messenger";
import systemNotificationsStyles from "../style.module.scss";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const { linkText } = systemNotificationsStyles;

type Props = {
  metadata: NewSpeakerAtTheEventMetadata;
};

const NewSpeakerAtEvent = ({ metadata }: Props) => {
  const {
    speaker_id: speakerId,
    event_id: eventId,
    event_name: eventTitle,
  } = metadata.data;

  const { data } = useGetUserQuery({ userId: speakerId });

  return (
    <NotificationTemplate>
      <div className={styles.info}>
        <Icons.Info color="tangerine1000" size="s" />
        <Text size="m" color="tangerine1000">
          New speaker at the event
        </Text>
      </div>
      <Link
        className={cn(styles.eventTitle, linkText)}
        size="m"
        color="violet"
        to={`/catalog/event/${eventId}`}
        underline={false}
        hoverUnderLine={false}
      >
        {eventTitle}
      </Link>
      {data && (
        <div className={styles.speakerContainer}>
          <Avatar size="s" src={data.user.avatar_url} status="offline" alt="" />
          <Text size="m">{`${data.user.first_name} ${data.user.last_name}`}</Text>
        </div>
      )}
    </NotificationTemplate>
  );
};

export default NewSpeakerAtEvent;
