// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unstable-nested-components */
import { useState } from "react";
import { Button, Input, Text, VisibilityButton } from "@evvve/ui-kit";
import { useModal } from "src/hooks";
import {
  useDeletePasswordMutation,
  useGetCurrentUserQuery,
} from "src/store/User/api";
import { FormikProps } from "formik/dist/types";
import { Formik, FormikHelpers } from "formik";
import { ApiError, parseErrorMessage } from "src/helpers/apiHelpers";
import { useAppDispatch } from "src/hooks/useStoreHooks";
import { addToast } from "src/store/toast/slice";
import { useValidationByInput } from "src/hooks/useValidationByInput";
import { validateCurrentPassword } from "src/helpers/validation";
import * as Yup from "yup";
import style from "./style.module.scss";
import ModalTemplate from "../ModalTemplate";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../../../constants";
import { setUserInfo } from "../../../../store/User/slice";

const { title, attention, submit, inputs, delete_modal } = style;

interface DeletePasswordForm {
  currentPassword: string;
}

const DeletePasswordModal = () => {
  const { isOpenModal, closeModal, openModal } = useModal();
  const [deleteUserPassword, { isLoading }] = useDeletePasswordMutation();
  const { refetch: userRefetch } = useGetCurrentUserQuery();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    currentPassword: validateCurrentPassword(),
  });

  const DeletePasswordForm = ({
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldError,
    values,
    errors,
    touched,
  }: FormikProps<DeletePasswordForm>) => {
    const isSubmitDisabled = useValidationByInput(touched, errors);

    return (
      <form onBlur={handleBlur} onSubmit={(e) => handleSubmit(e)}>
        <div className={inputs}>
          <Input
            label="Password"
            name="currentPassword"
            value={values?.currentPassword || ""}
            placeholder="Password"
            onChange={(e) => {
              setFieldError("currentPassword", "");
              handleChange(e);
            }}
            type={isPasswordVisible ? "text" : "password"}
            error={touched.currentPassword && errors.currentPassword}
            endAdornment={
              <VisibilityButton
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                showPassword={isPasswordVisible}
              />
            }
          />
        </div>
        <div className={submit}>
          <Button
            type="solid"
            theme="violet"
            size="l"
            disabled={isLoading || isSubmitDisabled}
            htmlType="submit"
            isLoading={isLoading}
          >
            Delete
          </Button>
        </div>
      </form>
    );
  };

  return (
    <ModalTemplate
      isOpen={isOpenModal}
      className={delete_modal}
      onClose={() => {
        closeModal();
        setIsPasswordVisible(false);
      }}
      OpenElement={
        <Button
          size="m"
          theme="violet"
          type="flat"
          onClick={() => {
            openModal();
          }}
        >
          Delete password
        </Button>
      }
    >
      <div className={title}>
        <Text size={[6, 6, 4]} bold>
          Delete password
        </Text>
      </div>
      <div className={attention}>
        <Text size="m">
          After you delete your password, you will be able to log in via a one-time
          temporary code we will send to your email.
        </Text>
      </div>
      <Formik<DeletePasswordForm>
        initialValues={{ currentPassword: "" }}
        validateOnBlur
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={async (
          values,
          formikHelpers: FormikHelpers<DeletePasswordForm>,
        ) => {
          try {
            const requestValue = {
              password: values.currentPassword,
            };
            await deleteUserPassword(requestValue).unwrap();
            const response = await userRefetch().unwrap();
            dispatch(setUserInfo(response));
            formikHelpers.resetForm();
            closeModal();
            setIsPasswordVisible(false);
            dispatch(
              addToast({
                type: "default",
                content: "Password deleted",
                estimate: DEFAULT_NOTIFICATION_ESTIMATE,
              }),
            );
          } catch (error) {
            if (!(error as ApiError).data) throw error;
            const errorData = parseErrorMessage(error as ApiError);
            if (errorData.code === "wrong_password") {
              formikHelpers.setFieldError(
                "currentPassword",
                "The password is incorrect. Please try again",
              );
            }
          }
        }}
        component={DeletePasswordForm}
      />
    </ModalTemplate>
  );
};
export default DeletePasswordModal;
