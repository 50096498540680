import React, { PropsWithChildren } from "react";
import { Toast } from "@evvve/ui-kit";
import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import { removeToast } from "src/store/toast/slice";
import { useTabletType } from "../../hooks";
import { useInfoModals } from "../../hooks/useInfoModals";

export const ToastContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { toasts } = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();
  const isTablet = useTabletType();

  const infoModals = useInfoModals();

  return (
    <>
      {children}
      {infoModals}
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          onClose={() => dispatch(removeToast(toast.id))}
          open={toast.isOpen}
          position={isTablet ? "bottom" : "top"}
          autoHideDuration={toast.estimate}
        >
          {toast.content}
        </Toast>
      ))}
    </>
  );
};
