import { Button, Text } from "@evvve/ui-kit";
import React from "react";
import s from "./style.module.scss";
import { useMobileType } from "../../../hooks/useMobileType";
import { ModalControls } from "../../../hooks/useModal";
import StickyModal from "../FixedModal";

interface CookiesModalProps {
  modalControls: ModalControls;
  onClose: () => void;
}

export const CookiesModal = ({ modalControls, onClose }: CookiesModalProps) => {
  const isMobileViewport = useMobileType();

  return modalControls.isOpenModal ? (
    <StickyModal className={s.modal}>
      <>
        <div className={s.text}>
          <Text size={[6, 6, 5]} bold>
            Cookies
          </Text>
          <Text size={["m", "m", "l"]}>
            We use cookies to ensure you get the best experience on our website.
          </Text>
        </div>
        <Button
          size={isMobileViewport ? "m" : "xl"}
          className={s.button}
          onClick={onClose}
        >
          Got&nbsp;it
        </Button>
      </>
    </StickyModal>
  ) : (
    <></>
  );
};
