import { Button, Text } from "@evvve/ui-kit";
import React from "react";
import { ModalTemplate } from "../../../close-part/features/Modals";
import { useMobileType } from "../../../hooks/useMobileType";
import s from "./style.module.scss";
import { ModalControls } from "../../../hooks/useModal";

interface BrowserInformationModalProps {
  modalControls: ModalControls;
  onClose: () => void;
}

export const BrowserInformationModal = ({
  modalControls,
  onClose,
}: BrowserInformationModalProps) => {
  const isMobileViewport = useMobileType();

  return (
    <ModalTemplate
      isOpen={modalControls.isOpenModal}
      onClose={onClose}
      className={s.modal}
    >
      <Text size={[6, 6, 4]} bold>
        Browser information
      </Text>
      <Text size="m" className={s.content}>
        We recommend using the Chrome browser for stable operation and access to all
        functionality.
      </Text>
      <Button
        size={isMobileViewport ? "m" : "l"}
        className={s.button}
        onClick={onClose}
      >
        Continue
      </Button>
    </ModalTemplate>
  );
};
