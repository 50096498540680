import React, { FC } from "react";

import cn from "classnames";
import { Text } from "@evvve/ui-kit";
import s from "./styles.module.scss";
import { ChatList } from "./ui";

const ChatListView: FC = () => (
  <>
    <div className={cn(s.header)}>
      <Text size={["m", "m", "m", "m", 6]} bold>
        Chat
      </Text>
    </div>
    <ChatList />
  </>
);

export default ChatListView;
