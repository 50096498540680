import React from "react";
import { Text, Icons } from "@evvve/ui-kit";

import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const eventDurationExceededStatic =
  "Your event was so great that the allotted 3 hours flew by unnoticed. " +
  "Please end the event within 15 minutes, or it will terminate automatically.";

const EventDurationExceeded = () => (
  <NotificationTemplate>
    <div className={styles.info}>
      <Icons.Info color="tangerine1000" size="s" />
      <Text size="m" color="tangerine1000">
        Event duration exceeded
      </Text>
    </div>
    <Text size="xs" color="grayscale900" as="p">
      {eventDurationExceededStatic}
    </Text>
  </NotificationTemplate>
);

export default EventDurationExceeded;
