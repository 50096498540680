// FIX_ME:
/* eslint-disable import/no-cycle */
import { useState, useEffect } from "react";
import { useScrollLock } from ".";

export interface ModalControls {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
}

/*
PROD-974 bugfix
blockScroll=true causes a scrollbar and therefore content flickering in the Microsoft Edge.
Alternatives:
1) html // overflow: overlay;
2) html // overflow-y: scroll;
lead to page auto scroll to the left top corner in Chrome.
 */
export const useModal = (blockScroll = true): ModalControls => {
  const { lockScroll, unlockScroll } = useScrollLock();
  const [isOpenModal, setOpenModal] = useState(false);

  useEffect(
    () => () => {
      if (isOpenModal && blockScroll) unlockScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpenModal],
  );

  const openModal = () => {
    setOpenModal(true);
    if (blockScroll) lockScroll();
  };
  const closeModal = () => {
    setOpenModal(false);
    if (blockScroll) unlockScroll();
  };

  return {
    isOpenModal,
    openModal,
    closeModal,
  };
};
