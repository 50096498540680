// FIX_ME:
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement } from "react";
import ReactDOM from "react-dom";
import { noop } from "lodash";
import style from "./style.module.scss";

interface ModalBackProps {
  children: ReactElement;
  isOpen: boolean;
  onClose: () => void;
  withoutBackground?: boolean;
  parentEl?: HTMLElement;
  desktopLayoutOnly?: boolean;
  resetDefaultPosition?: boolean;
}

const ModalBack = ({
  children,
  isOpen,
  onClose,
  withoutBackground = false,
  desktopLayoutOnly,
  parentEl = document.body,
  resetDefaultPosition = false,
}: ModalBackProps) => {
  const { wrap, background, children_wrap } = style;

  return isOpen ? (
    ReactDOM.createPortal(
      <div className={`${desktopLayoutOnly ? style.desktopOnly : ""} ${wrap}`}>
        {!withoutBackground && (
          <div
            className={background}
            onClick={onClose}
            onKeyDown={noop}
            role="button"
            tabIndex={0}
            aria-labelledby="TODO:"
          />
        )}
        <div className={resetDefaultPosition ? "" : children_wrap}>{children}</div>
      </div>,
      parentEl,
    )
  ) : (
    <></>
  );
};

export default ModalBack;
