import React from "react";
import { Text, Icons } from "@evvve/ui-kit";

import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const welcomeToEvveTextStatic = (
  <>
    We’re so glad you joined us.
    <br />
    Through this system dialogue, you will receive important notifications from our
    platform.
  </>
);

const WelcomeToPlatform = () => (
  <NotificationTemplate>
    <div className={styles.info}>
      <Icons.Info color="tangerine1000" size="s" />
      <Text size="m" color="tangerine1000">
        Welcome to Evvve!
      </Text>
    </div>
    <Text size="xs" color="grayscale900" as="p">
      {welcomeToEvveTextStatic}
    </Text>
  </NotificationTemplate>
);

export default WelcomeToPlatform;
