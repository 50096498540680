import { FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";

export const useValidationByInput = <FormModel extends object>(
  touched?: FormikTouched<FormModel>,
  errors?: FormikErrors<FormModel>,
) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (!touched || !errors) return;
    const hasError = Object.entries(touched).some(
      ([fieldName, isTouched]) => isTouched && errors[fieldName as keyof FormModel],
    );
    setIsDisabled(hasError);
  }, [touched, errors]);

  return isDisabled;
};
