import { messengerApi } from "src/store/messenger";

export const usePrefetchChatData = () => {
  const prefetchChatInfo = messengerApi.usePrefetch("getChatInfoById");
  const prefetchMessegeList = messengerApi.usePrefetch("getMessagesHistory");

  const prefetcChatData = (chatId: string) => () => {
    prefetchChatInfo({ chatId });
    prefetchMessegeList({ chatId });
  };

  return prefetcChatData;
};
