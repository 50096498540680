import { FC, useEffect } from "react";
import { Text } from "@evvve/ui-kit";
import { messengerApi } from "src/store/messenger";
import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";
import { Placeholder } from "src/shared/ui";
import { noop } from "lodash";

type Props = {
  onClick: VoidFunction;
  shouldShow?: boolean;
};

const ChatParticipantsCounter: FC<Props> = ({ onClick, shouldShow }) => {
  const { chatId } = useMessengerViewController();

  const [
    getChatParticipantsQuery,
    { data: participants, isUninitialized, isLoading },
  ] = messengerApi.useLazyGetChatParticipantsQuery();

  useEffect(() => {
    // if (!chatId) throw new Error("[CHAT]: there is not chatId");
    if (!chatId) return;

    if (isUninitialized) getChatParticipantsQuery({ chatId });
  }, [chatId, getChatParticipantsQuery, isUninitialized]);

  if (!shouldShow) return null;

  const count = participants?.length;

  return isLoading || !count ? (
    <Placeholder width="60%" height="19px" />
  ) : (
    // TODO: написать функцию плюрализации существительных
    // в разных языках она происходит по разному, сейчас некритично, т.к. у нас есть только англ
    <div onClick={onClick} role="button" onKeyDown={noop} tabIndex={0}>
      <Text size="xs" color="grayscale600">
        {`${count} ${count > 1 ? "participants" : "participant"}`}
      </Text>
    </div>
  );
};

export default ChatParticipantsCounter;
