import React from "react";

import styles from "./style.module.scss";

type Props = {
  position?: "right" | "left";
};

const positionStyle = {
  left: styles.positionLeft,
  right: styles.positionRight,
};

const NotificationTemplate = ({
  children,
  position,
}: React.PropsWithChildren<Props>) => {
  const pos = position ?? "left";

  return (
    <div className={positionStyle[pos]}>
      <div className={styles.wrap}>{children}</div>
    </div>
  );
};

export default NotificationTemplate;
