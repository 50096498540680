import { ChatMessageDTO } from "src/store/messenger";

export const sortMessagsByDatetime = (messages?: ChatMessageDTO[]) => {
  if (Array.isArray(messages)) {
    const clonedDataList = [...messages];

    return clonedDataList.sort((a, b) => {
      const aDateTime = new Date(a.datetime).getTime();
      const bDateTime = new Date(b.datetime).getTime();

      return aDateTime < bDateTime ? -1 : 1;
    });
  }

  return [];
};
