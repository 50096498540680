// FIX_ME:
/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";

export const fileReader = (
  e: React.FormEvent<HTMLInputElement> | File,
  setState: (value: string) => void,
  afterLoad?: () => void,
) => {
  const curFile = e instanceof File ? e : e?.currentTarget?.files?.[0];
  const reader = new FileReader();
  if (curFile) {
    reader.readAsDataURL(curFile);
    // FIX_ME:

    // eslint-disable-next-line @typescript-eslint/no-shadow
    reader.onload = (e) => {
      setState(`${e.target?.result}`);
      afterLoad?.();
    };
  }
};
