export interface ApiErrorDetail {
  code: string | undefined;
  message: string;
}
export interface ApiErrorDetailModelValidation {
  msg: string;
  type: string;
}

export interface ApiError {
  data: {
    detail: ApiErrorDetail;
  };
}

export const parseErrorMessage = (error: ApiError): ApiErrorDetail => {
  const errorData = error?.data.detail;
  if (typeof errorData === "object") {
    return errorData;
  }
  if (Array.isArray(errorData)) {
    const e: ApiErrorDetailModelValidation = errorData[0] ?? undefined;

    return { message: e?.msg ?? "Unknown error", code: e?.type };
  }

  return { message: "Unknown error", code: undefined };
};
