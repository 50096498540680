import { SystemMessageVariant } from "src/store/messenger";

export const systemMessagesLib = {
  [SystemMessageVariant.EVENT_DETAILS_HAVE_CHANGED]: "Event details have changed",
  [SystemMessageVariant.EVENT_DURATION_EXCEEDED]: "Event duration exceeded",
  [SystemMessageVariant.EVENT_HAS_BEEN_CANCELLED]: "The event has been cancelled",
  [SystemMessageVariant.EVENT_IS_LIVE]: "The event is live!",
  [SystemMessageVariant.EVENT_STARTS_IN_15_MIN]: "The event starts in 15 min",
  [SystemMessageVariant.EVENT_STARTS_IN_3_HOURS]: "The event starts in 3 hours",
  [SystemMessageVariant.INVITATION_TO_GO_LIVE]: "Invitation to go live",
  [SystemMessageVariant.I_ACCEPTED_TO_BE_SPEAKER_AT_EVENT]:
    "I accepted to be a speaker at the event",
  [SystemMessageVariant.I_AM_ORGANIZING_EVENT]: "I am organizing the event",
  [SystemMessageVariant.I_AM_SPEAKER_AT_EVENT]: "I'm a speaker at the event",
  [SystemMessageVariant.I_WILL_NOT_BE_SPEAKER_AT_EVENT]:
    "I won’t be a speaker at the event",
  [SystemMessageVariant.NEW_SPEAKER_AT_EVENT]: "New speaker at the event",
  [SystemMessageVariant.WELCOME_TO_EVENT]: "Welcome to the event",
  [SystemMessageVariant.WELCOME_TO_PLATFORM]: "Welcome to Evvve!",
  [SystemMessageVariant.YOU_HAVE_BEEN_INVITED_TO_BE_SPEAKER]:
    "You've been invited to be a speaker",
};
