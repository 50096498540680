import { FC } from "react";
import { ChatMessageDTO, ChatVariant, MessageVariant } from "src/store/messenger";
import { useAppSelector } from "src/hooks/useStoreHooks";
import cn from "classnames";
import { SystemMessageItem } from "../system-message-item";
import { RegularMessageItem } from "../regular-message-item";
import s from "./styles.module.scss";

type Props = {
  nextMessage?: ChatMessageDTO;
  message: ChatMessageDTO;
  chatVariant?: ChatVariant;
};

const MessageItem: FC<Props> = ({ nextMessage, message, chatVariant }) => {
  const me = useAppSelector((state) => state.user.userInfo);

  const isNextMessgeHaveSameSender =
    nextMessage && nextMessage.user?.id === message.user?.id;
  const isOutgoingMessage = message.user?.id === me?.unique_id;

  // NOTE: Если следующее сообщение - от другого пользователя, значит у текущего сообщения должен появиться уголок
  const shouldShowCorner = !isNextMessgeHaveSameSender;
  const isGroupChat = chatVariant === ChatVariant.GROUP;

  // NOTE: Если следующее сообщение - от другого пользователя и это не currentUser, значит у текущего сообщения
  // должен появиться аватар. Только для групповых диалогов
  const shouldShowAvatar =
    isGroupChat && !!(!isNextMessgeHaveSameSender && !isOutgoingMessage);

  const isSystemMessage = message.type === MessageVariant.SYSTEM;

  const renderMessageItem = () => {
    switch (true) {
      case isSystemMessage:
        return <SystemMessageItem message={message} key={message.id} />;
      default:
        return (
          <RegularMessageItem
            message={message}
            key={message.id}
            shouldShowAvatar={shouldShowAvatar}
            shouldShowCorner={shouldShowCorner}
            isOutgoingMessage={isOutgoingMessage}
            shouldShowCaption={isGroupChat}
            isGroupChat={isGroupChat}
          />
        );
    }
  };

  return (
    <div className={cn(s.message_wrap)} id={message.id}>
      {renderMessageItem()}
    </div>
  );
};

export default MessageItem;
