// FIX_ME:
/* eslint-disable react/no-unused-prop-types */
import React, { FC, PropsWithChildren } from "react";
import LogoLink from "src/shared-features/LogoLink/LogoLink";
import style from "./style.module.scss";
import { useAppSelector } from "../../../../hooks/useStoreHooks";

export interface HeaderProps extends PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
  type?: "public" | "private";
  desktopLayoutOnly?: boolean;
}

const HeaderLayout: FC<HeaderProps> = ({
  children,
  className,
  type = "public",
  desktopLayoutOnly,
}) => {
  const user = useAppSelector((state) => state.user.userInfo);

  return (
    <div
      className={`${style.container} 
    ${desktopLayoutOnly ? style.desktopOnly : ""}`}
    >
      <header className={`${style.header} ${className}`}>
        <div className={style.logo}>
          <LogoLink
            path={type === "public" ? "/" : `/profile/${user?.username}`}
            theme="dark"
          />
        </div>
        {children}
      </header>
    </div>
  );
};
export default HeaderLayout;
