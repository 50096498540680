import React from "react";
import cn from "classnames";
import s from "./style.module.scss";

interface FixedModalProps {
  children: React.ReactNode;
  className?: string;
}

export const FixedModal = ({ children, className }: FixedModalProps) => (
  <div className={cn(s.stickyModal, className)}>{children}</div>
);
