import React from "react";
import { Button, Text } from "@evvve/ui-kit";
import { useModal } from "src/hooks";
import style from "./style.module.scss";
import ModalTemplate from "../ModalTemplate";

const { title, attention, buttons } = style;

export interface Props {
  onOpen: (open: () => void) => void;
  onAccept?: () => void;
  onReject?: () => void;
  disabled?: boolean;
}

const DeleteAccountModal = ({ onOpen, onAccept, onReject, disabled }: Props) => {
  const { isOpenModal, closeModal, openModal } = useModal();

  return (
    <ModalTemplate
      isOpen={isOpenModal}
      onClose={closeModal}
      OpenElement={
        <Button
          size="m"
          theme="red"
          type="outline"
          disabled={disabled}
          onClick={() => onOpen?.(openModal)}
        >
          Delete account
        </Button>
      }
    >
      <div className={title}>
        <Text size={[6, 6, 6, 6, 4]} bold>
          Delete account?
        </Text>
      </div>
      <div className={attention}>
        <Text size="m">
          After you press the red button below, your account data will be deleted
          from the platform. This action is irreversible.
        </Text>
        <Text size="m">
          <b>Note:</b> All events you created will also be permanently deleted.
        </Text>
      </div>
      <div className={buttons}>
        <Button type="solid" theme="red" size="l" onClick={() => onAccept?.()}>
          Delete
        </Button>
        <Button
          type="outline"
          theme="red"
          size="l"
          onClick={() => {
            onReject?.();
            closeModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </ModalTemplate>
  );
};
export default DeleteAccountModal;
