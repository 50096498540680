import { useEffect, useRef } from "react";

export const useIsFirstUpdate = () => {
  const isFirstUpdate = useRef(true);

  useEffect(() => {
    isFirstUpdate.current = false;
  }, []);

  return isFirstUpdate.current;
};
