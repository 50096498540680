// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
import { Text } from "@evvve/ui-kit";
import style from "./style.module.scss";

const { user_name } = style;

export interface EmailProps {
  email: string;
  className?: string;
}

const Email = ({ email, className }: EmailProps) => (
  <div className={`${user_name} ${className}`}>
    <Text color="grayscale900" size="m">
      {email}
    </Text>
  </div>
);
export default Email;
