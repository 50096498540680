import React, { FC } from "react";

import { Icons, Text, Avatar } from "@evvve/ui-kit";

import Link from "src/shared-features/Link";
import { useGetUserQuery } from "src/store/User/api";

import { changeDateFormat } from "src/helpers/date";
import { YouHaveBeenInvitedToBeASpeakerMetadata } from "src/store/messenger";
import systemNotificationsStyles from "../style.module.scss";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const { linkText } = systemNotificationsStyles;

type Props = {
  metadata: YouHaveBeenInvitedToBeASpeakerMetadata;
};

const YouInvitedAsSpeaker: FC<Props> = ({ metadata }) => {
  const {
    organizer_id: organizerId,
    event_date: eventDate,
    event_id: eventId,
    event_name: eventTitle,
  } = metadata.data;

  const { data } = useGetUserQuery({ userId: organizerId });

  return (
    <NotificationTemplate>
      <div className={styles.info}>
        <Icons.Info color="tangerine1000" size="s" />
        <Text size="m" color="tangerine1000">
          You’ve been invited to be a speaker at
        </Text>
      </div>
      <Link
        className={[styles.eventTitle, linkText].join(" ")}
        size="m"
        color="violet"
        to={`/catalog/event/${eventId}`}
        underline={false}
        hoverUnderLine={false}
      >
        {eventTitle}
      </Link>
      <Text size="xs" as="p" className={styles.dateInscription}>
        Date
      </Text>
      <Text size="xs" as="p" className={styles.dateValue}>
        {changeDateFormat(eventDate)}
      </Text>
      {data && (
        <>
          <Text size="xs" as="p" className={styles.organizerInscription}>
            Organizer
          </Text>
          <div className={styles.organizerContainer}>
            <Avatar size="s" src={data.user.avatar_url} status="offline" alt="" />
            <Text size="m">{`${data.user.first_name} ${data.user.last_name}`}</Text>
          </div>
        </>
      )}
    </NotificationTemplate>
  );
};

export default YouInvitedAsSpeaker;
