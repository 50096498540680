// FIX_ME:
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { messengerApi } from "../../api";

type Args = {
  chatId: string;
};

export const updateMessageListOnReadLastMsg = ({ chatId }: Args) =>
  messengerApi.util.updateQueryData("getMessagesHistory", { chatId }, (draft) => {
    draft.data.forEach((message) => {
      if (message.isUnread) message.isUnread = false;
    });
  });
