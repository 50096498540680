import { FC } from "react";
import cn from "classnames";
import { Icons } from "@evvve/ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { noop } from "lodash";
import s from "./style.module.scss";
import { useGetChatMateComplex, useMessengerViewController } from "../../hooks";
import { MessengerViewVariant } from "../../types";

import {
  ProfileAdditionalInfo,
  StartChatAction,
  SubscriptionToggle,
  SubscriptionsInfo,
  ProfileHead,
} from "./ui";
import { useAppSelector } from "../../../../../hooks/useStoreHooks";
import ChatSpinner from "../chat-spinner/ChatSpinner";

type LocationState = {
  previousSearch: string;
};

const ProfileView: FC = () => {
  const { goToView } = useMessengerViewController();

  const { chatMateProfile, isLoading } = useGetChatMateComplex();

  const navigate = useNavigate();
  const locationState = useLocation().state as LocationState;

  const currentUser = useAppSelector((state) => state.user.userInfo);
  const {
    user: userProfileInfo,
    privacy,
    is_following: isAlreadyFollowMe,
    is_can_start_chat: isCanStartChat,
  } = chatMateProfile || {};

  const closeProfileViewHandler = () => {
    if (locationState?.previousSearch) navigate(locationState.previousSearch);
    else goToView(MessengerViewVariant.CHAT_BY_ID);
  };

  const thereIsJobInfo = userProfileInfo?.position || userProfileInfo?.company;

  const hasUserFullInfo = thereIsJobInfo || userProfileInfo?.social_links?.length;

  const isPrivateChat = privacy === "private";

  const shouldShowPreloader = (isLoading && !chatMateProfile) || !userProfileInfo;
  const isCurrentUserProfileOwner =
    !!currentUser &&
    !!userProfileInfo &&
    currentUser.unique_id === userProfileInfo.unique_id;

  if (shouldShowPreloader) return <ChatSpinner />;

  return (
    <div className={cn(s.wrap)}>
      {/* TODO: нужно избавляться от этих бесконечных бестолковых врапперов,  */}
      {/* каждому компоненту из ui-kit нужно обязательно пробрасывать  */}
      {/* className и style props  */}
      <div
        className={s.closeButton}
        onClick={closeProfileViewHandler}
        role="button"
        onKeyDown={noop}
        tabIndex={0}
        aria-labelledby="TODO:"
      >
        <Icons.X size="m" color="violet" />
      </div>
      {/* <Person */}
      {/*    profileOwner={chatMateProfile.user} */}
      {/*    isCurrentUserProfileOwner={isCurrentUserProfileOwner} */}
      {/*    profilePrivacy={chatMateProfile.privacy} */}
      {/*    isCurrentUserCanSendMessageToProfileOwner={chatMateProfile.is_can_start_chat} */}
      {/*    isCurrentUserFollowsProfileOwner={chatMateProfile.is_following} */}
      {/* /> */}
      <div className={cn(s.head_and_info, { [s.mb_16_mobile]: !hasUserFullInfo })}>
        <ProfileHead profileInfo={userProfileInfo} />
        <SubscriptionsInfo
          followersCount={userProfileInfo.followers_count}
          followingCount={userProfileInfo.following_count}
          shouldHide={isPrivateChat}
        />
      </div>
      <SubscriptionToggle
        amIFollower={!!isAlreadyFollowMe}
        userId={userProfileInfo.unique_id}
        shouldHide={isPrivateChat}
        disabled={isCurrentUserProfileOwner}
      />
      {!(isPrivateChat && !isCanStartChat) && (
        <StartChatAction
          isAbleToChat={isCanStartChat}
          userFirstName={userProfileInfo.first_name}
          userId={userProfileInfo.unique_id}
          disabled={isCurrentUserProfileOwner}
        />
      )}
      <ProfileAdditionalInfo userInfo={userProfileInfo} shouldHide={isPrivateChat} />
    </div>
  );
};

export default ProfileView;
