import { useEffect, useState } from "react";

export const useMobileType = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 767px)").matches,
  );
  const changeMediaWidth = () => {
    setIsMobile(window.matchMedia("(max-width: 767px)").matches);
  };
  useEffect(() => {
    window.addEventListener("resize", changeMediaWidth, true);

    return () => {
      window.removeEventListener("resize", changeMediaWidth);
    };
  }, []);

  return isMobile;
};
