import React, { FC } from "react";

import { Icons, Text } from "@evvve/ui-kit";

import Link from "src/shared-features/Link";
import { changeDateFormat, changeTimeFormat } from "src/helpers/date";

import { EventDetailsHaveChangedMetadata } from "src/store/messenger";
import systemNotificationsStyles from "../style.module.scss";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

const { linkText } = systemNotificationsStyles;

type Props = {
  metadata: EventDetailsHaveChangedMetadata;
};

const EventDetailsHaveChanged: FC<Props> = ({ metadata }) => {
  const {
    event_id: eventId,
    event_name: eventName,
    new_date: newDate,
    new_start_time: newStartTime,
    new_end_time: newEndTime,
  } = metadata.data;

  return (
    <NotificationTemplate>
      <div className={styles.info}>
        <Icons.Info color="tangerine1000" size="s" />
        <Text size="m" color="tangerine1000">
          Event details have changed
        </Text>
      </div>
      <Link
        className={[styles.eventTitle, linkText].join(" ")}
        size="m"
        color="violet"
        to={`/catalog/event/${eventId}`}
        underline={false}
        hoverUnderLine={false}
      >
        {eventName}
      </Link>
      {newDate && (
        <div className={styles.newDate}>
          <Text
            className={styles.changedDateInscription}
            size="xs"
            color="grayscale800"
            as="span"
          >
            New date
          </Text>
          <Text size="xs" color="grayscale800" as="span">
            {changeDateFormat(newDate)}
          </Text>
        </div>
      )}
      {newStartTime && newEndTime && (
        <div>
          <Text
            className={styles.changedTimeInscription}
            size="xs"
            color="grayscale800"
            as="span"
          >
            New time
          </Text>
          <Text size="xs" color="grayscale800" as="span">
            {`${changeTimeFormat(newStartTime)}\u2013${changeTimeFormat(
              newEndTime,
            )}`}
          </Text>
        </div>
      )}
    </NotificationTemplate>
  );
};

export default EventDetailsHaveChanged;
