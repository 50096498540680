import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CHAT_API_BASE_URL } from "../constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${CHAT_API_BASE_URL}`,
  prepareHeaders: (headers) => {
    headers.set("Access-Control-Allow-Origin", "*");
    const chatToken = localStorage.getItem("chat-token");
    if (chatToken) {
      headers.set("Authorization", `Bearer ${chatToken}`);
    }

    return headers;
  },
});

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery,
  tagTypes: ["ChatList", "MessageList", "Chat"],
  endpoints: () => ({}),
});
