/* eslint-disable implicit-arrow-linebreak */
import React, { FC, useMemo } from "react";
import { Button } from "@evvve/ui-kit";
import { useFollowUnfollowUserMutation } from "src/store/User/api";
import s from "./style.module.scss";

type Props = {
  amIFollower: boolean;
  userId?: string;
  shouldHide?: boolean;
  disabled: boolean;
};

const SubscriptionToggle: FC<Props> = ({
  amIFollower,
  userId,
  shouldHide,
  disabled,
}) => {
  const [toggleFollow, { isLoading }] = useFollowUnfollowUserMutation();

  const onToggleFollow = () => {
    if (!userId) throw new Error("[CHAT]: there is no userId");
    toggleFollow({ amIFollower, uniqueId: userId });
  };

  const startIconName = useMemo(() => {
    if (isLoading) return undefined;

    return amIFollower ? "UserMinus" : "UserPlus";
  }, [isLoading, amIFollower]);

  const text = amIFollower ? "Unfollow" : "Follow";

  return shouldHide ? null : (
    <div className={s.followButton}>
      <Button
        theme="violet"
        type="solid"
        size="s"
        onClick={onToggleFollow}
        startIcon={startIconName}
        disabled={isLoading || disabled}
        isLoading={isLoading}
      >
        {text}
      </Button>
    </div>
  );
};

export default SubscriptionToggle;
