/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { FC } from "react";
import cn from "classnames";
import Link from "src/shared-features/Link";
import { CurrentUser, SocialLink, WebResourceVariant } from "src/store/User/types";
import { Icons, Text } from "@evvve/ui-kit";
import {
  hasURLIncludeSubdomain,
  isValidHttpUrl,
  removeURLProtocolAndSubdomains,
} from "src/helpers/url";
import s from "./styles.module.scss";
import { ProfilePlaceholder } from "../profile-placeholder";

const ICON_COLOR = "violet600";

type SocialMedia = Exclude<WebResourceVariant, WebResourceVariant.WEBSITE>;

export const clenupWebsiteURL = (linkSite: string) =>
  isValidHttpUrl(linkSite) || hasURLIncludeSubdomain(linkSite)
    ? removeURLProtocolAndSubdomains(linkSite)
    : linkSite;

const socialMediaToIcon = {
  [WebResourceVariant.FACEBOOK]: <Icons.Facebook color={ICON_COLOR} />,
  [WebResourceVariant.INSTAGRAM]: <Icons.Instagram color={ICON_COLOR} />,
  [WebResourceVariant.LINKEDIN]: <Icons.Linkedin color={ICON_COLOR} />,
  [WebResourceVariant.TWITTER]: <Icons.Twitter color={ICON_COLOR} />,
};

type Props = {
  userInfo?: CurrentUser;
  shouldHide?: boolean;
};

type SocialMediaLink = {
  type: SocialMedia;
  url: string;
};

const ProfileAdditionalInfo: FC<Props> = ({ userInfo, shouldHide }) => {
  const shouldShowJobInfo = userInfo?.position || userInfo?.company;

  const isSocialMedia = (link: SocialLink) =>
    link.type !== WebResourceVariant.WEBSITE;

  const socialMedias = userInfo?.social_links?.filter(
    (link): link is SocialMediaLink => isSocialMedia(link),
  );

  const website = userInfo?.social_links?.find((link) => !isSocialMedia(link));

  if (shouldHide) return <ProfilePlaceholder />;

  return (
    <div
      className={cn(s.user_full_info, {
        [s.start_position_content]: !socialMedias?.length,
      })}
    >
      {shouldShowJobInfo && (
        <div className={s.job_info}>
          <Text size="m" color="grayscale600">
            {userInfo.position && userInfo.company
              ? `${userInfo.position},`
              : `${userInfo.position}`}
          </Text>
          <Text size="m" color="grayscale600">
            {userInfo.company}
          </Text>
        </div>
      )}

      <div className={s.description_wrap}>
        {(socialMedias?.length || website) && (
          <div className={s.social_networks}>
            {socialMedias?.length && (
              <div className={s.icons}>
                {socialMedias.map((socMedia) =>
                  socMedia.url ? (
                    <Link
                      key={socMedia.type}
                      to={socMedia.url}
                      external
                      target="_blank"
                      hoverUnderLine={false}
                    >
                      {socialMediaToIcon[socMedia.type]}
                    </Link>
                  ) : null,
                )}
              </div>
            )}
            {website && (
              <div className={s.link_my_site}>
                <Link color="blue" external target="_blank" to={website.url}>
                  {clenupWebsiteURL(website.url)}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileAdditionalInfo;
