import {
  AuthByCodeRequest,
  AuthByPasswordRequest,
  AuthEmailResponse,
  AuthResponse,
  GoogleAuthUrlResponse,
  RegistrationPayload,
  ResetPasswordRequest,
} from "./types";
import { appApi } from "../../api/api";

/*
Registration:
1. /auth/requestCode,
   Request: email
   Response: type="code"
2. /auth/login-with-code
   Request: email, code
   Response:
   - toke.access_token = temporary JWT to send later to /auth/register
   - token.refresh_token = null
   - metadata.registration = true
   - metadata.username = generated username
3. /auth/register
   Request: first name, last name, username (temporary JWT in Authorization header)
   Response:
   - access_token
   - refresh_token
   - metadata.registration = false
 */

const authApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    requestCode: builder.mutation<AuthEmailResponse, string>({
      query: (email: string) => ({
        url: "/auth/request-code",
        method: "POST",
        body: { email },
      }),
    }),
    register: builder.mutation<AuthResponse, RegistrationPayload>({
      query: (payload: RegistrationPayload) => ({
        url: "/auth/register",
        method: "POST",
        body: payload,
      }),
    }),
    loginWithCode: builder.mutation<AuthResponse, AuthByCodeRequest>({
      query: ({ email, code }) => ({
        url: "/auth/login-with-code",
        method: "POST",
        body: { email, code },
      }),
      invalidatesTags: ["UserEvents"],
    }),
    loginWithPassword: builder.mutation<AuthResponse, AuthByPasswordRequest>({
      query: ({ email, password }) => ({
        url: "/auth/login-with-password",
        method: "POST",
        body: { email, password },
      }),
    }),
    forgetPassword: builder.mutation<string, string>({
      query: (email: string) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation<AuthResponse, ResetPasswordRequest>({
      query: (request: ResetPasswordRequest) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: request,
      }),
    }),
    logout: builder.mutation<string, void>({
      query: () => ({
        url: "/health",
        method: "GET",
      }),
    }),
    getGoogleAuthUrl: builder.query<GoogleAuthUrlResponse, void>({
      query: () => "/auth/google/authorize",
    }),
    loginWithGoogle: builder.mutation<AuthResponse, URLSearchParams>({
      query: (params: URLSearchParams) => ({
        url: `/auth/google/callback?${params}`,
        method: "POST",
      }),
      invalidatesTags: ["UserEvents"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useRequestCodeMutation,
  useRegisterMutation,
  useLoginWithCodeMutation,
  useLoginWithPasswordMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useLazyGetGoogleAuthUrlQuery,
  useLoginWithGoogleMutation,
} = authApi;
