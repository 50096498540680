import { BASE_API_URL } from "src/constants";
import axios from "axios";

export interface S3PreSignedResponse {
  data: {
    fields: { [props: string]: string };
    url: string;
    public_url: string;
    key: string;
  };
}
export type FileType = "user_avatar" | "event_cover" | "attachment";

export const uploadFile = async (
  file: File,
  type: FileType,
  anonymous?: boolean,
): Promise<{ key: string; S3Data: S3PreSignedResponse } | null> => {
  if (!file) return null;
  const accessToken = localStorage.getItem("access-token");
  const anonymousStr = anonymous ? "-anonymous" : "";
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const s3PreSignedResponse: S3PreSignedResponse = await axios.post(
    `${BASE_API_URL}/pre-signed-s3-post${anonymousStr}?file_type=${type}`,
    null,
    config,
  );
  if (s3PreSignedResponse) {
    const formData = new FormData();
    Object.keys(s3PreSignedResponse.data.fields).forEach((key) => {
      formData.append(key, s3PreSignedResponse.data.fields[key]);
    });
    formData.append("file", file);
    const fileKeyData = await axios.post(
      `${s3PreSignedResponse.data.url}`,
      formData,
    );

    return { key: fileKeyData.data.key, S3Data: s3PreSignedResponse };
  }

  return null;
};

export const isImageValid = (file: File) => {
  const image = new Image();
  image.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    image.onload = () => {
      // Изображение загрузилось успешно, не является битым
      resolve(true);
    };

    image.onerror = () => {
      // Изображение не загрузилось или является битым
      resolve(false);
    };
  });
};
