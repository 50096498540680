// FIX_ME:
/* eslint-disable import/no-cycle */
import { AppDispatch } from "src/store/store";
import { updateChatListOnDeleteChat } from ".";
import { messengerApi } from "../..";

export const removeChatOnQueryStarted = async (
  requestArgs: { chatId: string },
  { dispatch, queryFulfilled }: { dispatch: AppDispatch; queryFulfilled: boolean },
) => {
  // TODO:
  const lastEvaluatedKey = null;

  const patchResult = dispatch(
    updateChatListOnDeleteChat({
      chatId: requestArgs.chatId,
      lastEvaluatedKey,
    }),
  );

  try {
    await queryFulfilled;
  } catch {
    patchResult.undo();
    dispatch(
      messengerApi.util.invalidateTags([
        { type: "Chat", id: requestArgs.chatId },
        { type: "MessageList", id: requestArgs.chatId },
      ]),
    );
  }
};
