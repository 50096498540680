import { Button, Text } from "@evvve/ui-kit";
import React from "react";
import ModalTemplate from "../ModalTemplate";
import style from "./style.module.scss";

export interface ModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  speakerView: React.ReactElement;
}
const { modalWrap } = style;

const RemoveSpeakerModal = ({
  isOpen,
  onClose,
  onSuccess,
  speakerView,
  isLoading,
}: ModalProps) => (
  <ModalTemplate
    isOpen={isOpen}
    onClose={onClose}
    className={modalWrap}
    title="Remove speaker?"
    description="You are about to remove the following speaker:"
    buttons={
      <>
        <Button size="m" type="solid" onClick={onSuccess} isLoading={isLoading}>
          Remove
        </Button>
        <Button size="m" type="outline" onClick={onClose}>
          Cancel
        </Button>
      </>
    }
  >
    <div className={style.name_item}>{speakerView}</div>
    <div className={style.description}>
      <Text size="m">
        {"They will get an email notification " +
          "that their performance is cancelled, and " +
          "all sessions assigned to them will be deleted from the event schedule."}
      </Text>
    </div>
  </ModalTemplate>
);
export default RemoveSpeakerModal;
