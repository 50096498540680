// FIX_ME:
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useStoreHooks";
import {
  refreshTokens,
  validateAccessToken,
} from "../../authorization/service/helpers";
import { setUserInfo } from "../../store/User/slice";
import { LoaderWrap } from "../LoaderWrap/LoaderWrap";

export const ClosePartWrapper = ({
  children,
}: {
  children?: React.ReactElement | React.ReactNode;
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [isRefreshingTokens, setIsRefreshingTokens] = useState(false);
  const [redirected, setRedirected] = useState(false);

  // FIX_ME:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isRefreshingTokens || redirected) return;

    const token = localStorage.getItem("access-token");
    if (validateAccessToken(token)) return;

    setIsRefreshingTokens(true);
    refreshTokens(
      () => {
        // no refreshToken or failed http request
        dispatch(setUserInfo(null));
        setRedirected(true);
        setIsRefreshingTokens(false);
      },
      () => setIsRefreshingTokens(false),
    );
  });

  useEffect(() => {
    if (!redirected) return;
    navigate("/login", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirected]);

  if (isRefreshingTokens || redirected) return <LoaderWrap />;

  return <>{children || <Outlet />}</>;
};
