import {
  useLazyFollowUserQuery,
  useLazyUnfollowUserQuery,
} from "src/store/User/api";

type HookArgs = {
  userId?: string;
  isAlreadyFollowMe: boolean;
};

export const useFollowUnfollow = ({ userId, isAlreadyFollowMe }: HookArgs) => {
  const [followQuery, { isLoading: isFollowProcessing }] = useLazyFollowUserQuery();
  const [unfollowQuery, { isLoading: isUnfollowProcessing }] =
    useLazyUnfollowUserQuery();

  const toggleFollow = () => {
    if (!userId) {
      throw new Error("[CHAT]: There is no chatMateId");
    }

    if (isAlreadyFollowMe) unfollowQuery({ uniqueId: userId });
    else followQuery({ uniqueId: userId });
  };

  return { toggleFollow, isLoading: isFollowProcessing || isUnfollowProcessing };
};
