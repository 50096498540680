import { FC } from "react";

import { Button, Text } from "@evvve/ui-kit";
import { CurrentUser } from "src/store/User/types";
import s from "./style.module.scss";
import { ReactComponent as MessageOffIcon } from "./assets/message_off.svg";
import { useMessengerViewController } from "../../../../hooks";
import { MessengerViewVariant } from "../../../../types";
import { useInitPrivateChatComplex } from "./hooks";

type Props = {
  userFirstName?: CurrentUser["first_name"];
  isAbleToChat?: boolean;
  userId?: string;
  disabled?: boolean;
};

const StartChatAction: FC<Props> = ({
  userId,
  userFirstName,
  isAbleToChat,
  disabled,
}) => {
  const { goToView } = useMessengerViewController();

  const { initChatHandler, isLoading: initChatIsLoading } =
    useInitPrivateChatComplex();

  const onStartChat = async () => {
    if (!isAbleToChat) throw new Error("[CHAT]: user is not able to chat");
    if (!userId) throw new Error("[CHAT]: userId doesn't exist");

    const chatDataRes = await initChatHandler({ userId });

    if (chatDataRes?.isSuccess) {
      goToView(MessengerViewVariant.CHAT_BY_ID, {
        chatId: chatDataRes.data.chat_id,
      });
    }

    if (chatDataRes?.isError) {
      // [CHAT] TODO: обработать ошибку ибо непонятно что должно
      // происходить в случае если чата нет и он не инитится - После релиза
    }
  };

  return (
    <div className={s.messageButton}>
      {isAbleToChat ? (
        <Button
          theme="violet"
          type="flat"
          size="s"
          onClick={onStartChat}
          startIcon="MessageSquare"
          disabled={initChatIsLoading || disabled}
        >
          Message
        </Button>
      ) : (
        <div className={s.buttonRestricteToMessage}>
          <MessageOffIcon />
          <Text size="s" color="grayscale600" className={s.text}>
            {`${userFirstName} has restricted\n messages from other users`}
          </Text>
        </div>
      )}
    </div>
  );
};

export default StartChatAction;
