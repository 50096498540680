import { Button } from "@evvve/ui-kit";
import React from "react";
import ModalTemplate from "../ModalTemplate";
import style from "./style.module.scss";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  isLoading: boolean;
  isSimple: boolean;
}
const { modalWrap } = style;

const ModalEventTypeSwitch = ({
  isOpen,
  onClose,
  onSuccess,
  isLoading,
  isSimple,
}: ModalProps) => {
  const title = isSimple ? "Switch to Complex event?" : "Switch to Simple event?";
  const description = isSimple
    ? "We’ll discard the start and end time. You’ll have to set event lineup."
    : "We’ll discard the created sessions and send the invited speakers a cancelation email.";

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      className={modalWrap}
      title={title}
      description={description}
      buttons={
        <>
          <Button
            size="m"
            type="solid"
            onClick={onSuccess}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Switch
          </Button>
          <Button size="m" type="outline" onClick={onClose}>
            Cancel
          </Button>
        </>
      }
    />
  );
};
export default ModalEventTypeSwitch;
