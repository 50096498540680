// FIX_ME:
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { memo, useState, useEffect, FC } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { Icons, Text, Spinner, Button } from "@evvve/ui-kit";

import { useModal } from "src/hooks";
import { useAppSelector } from "src/hooks/useStoreHooks";
import { useGetEventQuery, useGetSessionsQuery } from "src/store/events/api";
import { EventMemberRole } from "src/store/events/types";
import { ModalTemplate } from "src/close-part/features/Modals";

import { InvitationToGoLiveMetadata } from "src/store/messenger";
import styles from "./style.module.scss";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: InvitationToGoLiveMetadata;
};

const invitationCancelledByOrganizer =
  "The organizer has canceled your invitation to go on stage.";
const invitationCancelledBySpeaker = "You declined to speak at this session.";
const invitationCancelledStatics = {
  [EventMemberRole.ORGANIZER]: invitationCancelledByOrganizer,
  [EventMemberRole.SPEAKER]: invitationCancelledBySpeaker,
};

const cancelledInvitationModalStatics = {
  title: "Invitation declined",
  description:
    "The invitation to go on stage is no longer valid. " +
    "However, you are welocome to join the event broadcast as a viewer.",
};

const _InvitationToGoLive: FC<Props> = ({ metadata }) => {
  const {
    data: {
      event_id: eventId,
      session_id: sessionId,
      cancelled_by_organizer: cancelledByOrganizer,
      cancelled_by_speaker: cancelledBySpeaker,
    },
    sender_id: senderId,
  } = metadata;

  const navigate = useNavigate();

  const thisUserId = useAppSelector((state) => state.user.userInfo?.unique_id);
  const isThisUserSender = thisUserId === senderId;

  const isInvitationCancelled = cancelledByOrganizer || cancelledBySpeaker;

  let invitationCancelledBy:
    | EventMemberRole.ORGANIZER
    | EventMemberRole.SPEAKER
    | null;
  if (cancelledByOrganizer) invitationCancelledBy = EventMemberRole.ORGANIZER;
  else if (cancelledBySpeaker) invitationCancelledBy = EventMemberRole.SPEAKER;
  else invitationCancelledBy = null;

  // [state]s
  const [eventTitle, setEventTitle] = useState<string | null>(null);
  const [sessionTitle, setSessionTitle] = useState<string | null>(null);
  const {
    isOpenModal: isDeclinedInvitationModalOpen,
    closeModal: closeDeclinedInvitationModalOpen,
  } = useModal();

  // get event [concern]
  const getEventQueryState = useGetEventQuery(eventId, {
    skip: isInvitationCancelled,
  });

  // get event throw error [concern]
  useEffect(() => {
    if (getEventQueryState.isUninitialized || getEventQueryState.isLoading) return;

    if (getEventQueryState.isError) {
      const e = getEventQueryState.error;
      throw new Error(JSON.stringify(e));
    }

    if (!getEventQueryState.data)
      throw new Error(`Event id: ${eventId}\nEvent data is falsy`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventQueryState]);

  // get sessions [concern]
  const getSessionsQuery = useGetSessionsQuery(eventId, {
    skip: isInvitationCancelled,
  });

  // get sessions throw error [concern]
  useEffect(() => {
    if (getSessionsQuery.isUninitialized || getSessionsQuery.isLoading) return;

    if (getSessionsQuery.isError) {
      const e = getSessionsQuery.error;
      throw new Error(JSON.stringify(e));
    }

    if (!getSessionsQuery.data)
      throw new Error(`Event id: ${eventId}\nSessions data is falsy`);

    const ss = getSessionsQuery.data;
    const s = ss.find((item) => item.unique_id === sessionId);
    if (!s)
      throw new Error(`Event id: ${eventId}\nSession id: ${sessionId} is not found`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSessionsQuery]);

  // event title [reflex]
  useEffect(() => {
    if (!getEventQueryState.data) return;

    const t = getEventQueryState.data.name;

    if (!t) throw new Error("[CHAT]: t (?) is not defined");

    setEventTitle(t);
  }, [getEventQueryState]);

  // session title [reflex]
  useEffect(() => {
    if (!getSessionsQuery.data) return;

    const ss = getSessionsQuery.data;
    const s = ss.find((s) => s.unique_id === sessionId);
    s && setSessionTitle(s.name || "Untitled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSessionsQuery]);

  //

  const pendingInvitationBody = (
    <>
      <Text className={styles.caption} as="p" size="m" color="grayscale900">
        The session you are speaking at starts now, and the organizer calls you on
        stage.
      </Text>
      <Text
        className={styles.titleInscription}
        as="p"
        size="xs"
        color="grayscale800"
      >
        Event:
      </Text>
      {eventTitle ? (
        <Text className={styles.title} as="p" size="m" color="grayscale800" bold>
          {eventTitle}
        </Text>
      ) : (
        <Spinner className={styles.spinner} size="s" color="grayscale200" />
      )}
      <Text
        className={styles.titleInscription}
        as="p"
        size="xs"
        color="grayscale800"
      >
        Session:
      </Text>
      {sessionTitle ? (
        <Text className={styles.title} as="p" size="xs" color="grayscale800">
          {sessionTitle}
        </Text>
      ) : (
        <Spinner className={styles.spinner} size="s" color="grayscale200" />
      )}
      <div className={styles.buttons}>
        <Button type="outline">Decline</Button>
        <Button type="solid">Go on stage</Button>
      </div>
    </>
  );

  const revokedInvitationBody = (
    <Text as="p" size="xs" color="grayscale800">
      {invitationCancelledBy && invitationCancelledStatics[invitationCancelledBy]}
    </Text>
  );

  return (
    <>
      <NotificationTemplate position={isThisUserSender ? "right" : "left"}>
        <div className={styles.info}>
          <Icons.Info color="tangerine1000" size="s" />
          <Text size="m" color="tangerine1000">
            {!isInvitationCancelled ? "Invitation to go live" : "Ivitation revoked"}
          </Text>
        </div>
        {!isInvitationCancelled ? pendingInvitationBody : revokedInvitationBody}
      </NotificationTemplate>
      <ModalTemplate
        className={styles.declineInvitationTemplate}
        isOpen={isDeclinedInvitationModalOpen}
        onClose={closeDeclinedInvitationModalOpen}
        title={cancelledInvitationModalStatics.title}
        description={cancelledInvitationModalStatics.description}
        buttons={
          <div className={styles.modalButtons}>
            <Button
              type="outline"
              theme="violet"
              onClick={closeDeclinedInvitationModalOpen}
            >
              Cancel
            </Button>
            <Button
              type="solid"
              theme="violet"
              onClick={() => navigate(`catalog/event/${eventId}/space/stage`)}
            >
              Watch the broadcast
            </Button>
          </div>
        }
      />
    </>
  );
};

const InvitationToGoLive = (props: Props) => (
  <ErrorBoundary>
    <_InvitationToGoLive {...props} />
  </ErrorBoundary>
);

export default memo(InvitationToGoLive);
