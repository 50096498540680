import Link from "src/shared-features/Link";
import { Button } from "@evvve/ui-kit";
import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import { logout } from "src/store/User/slice";
import { useNavigate } from "react-router-dom";
import { appApi } from "src/api/api";
import { chatApi } from "src/api/chatApi";
import { apiPayment } from "src/api/apiPayment";
import cn from "classnames";
import { useLogoutMutation } from "../../../../authorization/service/api";
import style from "./style.module.scss";

export interface Props {
  openMenu?: boolean;
}

const HeaderMenu = ({ openMenu }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const [logoutAndInvalidateCaches] = useLogoutMutation();
  const responsiveLayout = useAppSelector(
    (state) => state.user.responsiveLayoutConfig,
  );

  const resetStore = () => {
    dispatch(appApi.util.resetApiState());
    dispatch(chatApi.util.resetApiState());
    dispatch(apiPayment.util.resetApiState());

    dispatch({ type: "RESET" });
  };

  const cleanUpLocalStorage = () => {
    localStorage.removeItem("chat-refresh-token");
    localStorage.removeItem("note-cookies");
  };

  const onLogOut = () => {
    navigate("/");
    dispatch(logout());

    resetStore();
    cleanUpLocalStorage();

    logoutAndInvalidateCaches().unwrap();
  };

  return responsiveLayout ? (
    <div
      className={cn({
        [style.wrap]: openMenu,
        [style.close]: !openMenu,
        [style.wrap_mobile]: responsiveLayout.isMobile,
      })}
    >
      <Link to={`/profile/${user?.username}`} hoverUnderLine={false} color="violet">
        Profile
      </Link>
      <hr className={`${style.divider} solid`} />
      <Link to="/" hoverUnderLine={false} color="violet">
        Homepage
      </Link>
      <Link to="/catalog" hoverUnderLine={false} color="violet">
        Event catalog
      </Link>
      <Link to="/contacts" hoverUnderLine={false} color="violet">
        Contacts
      </Link>
      <hr className={`${style.divider} solid`} />
      <Link to="/privacy" hoverUnderLine={false} color="violet">
        Privacy Policy
      </Link>
      <Link to="/terms" hoverUnderLine={false} color="violet">
        Terms of Service
      </Link>
      {/* <Link to="/learning" hoverUnderLine={false} color="violet"> */}
      {/*          Learning */}
      {/* </Link> */}
      <hr className={`${style.divider} solid`} />
      <Button theme="red" type="flat" size="s" onClick={onLogOut}>
        Log out
      </Button>
    </div>
  ) : (
    <></>
  );
};
export default HeaderMenu;
