import { useEffect, useState } from "react";

export const useDesktopType = (minWidth = 1280) => {
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia(`(min-width: ${minWidth}px)`).matches,
  );
  const changeMediaWidth = () => {
    const desktopDetector = window.matchMedia(`(min-width: ${minWidth}px)`).matches;
    setIsDesktop(desktopDetector);
  };
  useEffect(() => {
    window.addEventListener("resize", changeMediaWidth, true);

    return () => {
      window.removeEventListener("resize", changeMediaWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isDesktop;
};
