// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
import { FormikProps } from "formik/dist/types";
import { useEffect } from "react";
import { Button, Icons, Input, Text } from "@evvve/ui-kit";
import { useOutletContext } from "react-router-dom";
import { LoaderWrap } from "../../../../../shared-features/LoaderWrap/LoaderWrap";
import { SocialMediaFormType } from "./service/types";
import { IOutletContext } from "../../service/types";
import style from "./style.module.scss";
import Honeycomb from "../../../../../shared-features/Honeycomb/Honeycomb";

interface SocialMediaFormProps extends FormikProps<SocialMediaFormType> {
  setDirty?: (dirty: boolean) => void;
  isLoadingPatchUserSocialMedia: boolean;
}

const { Twitter, Linkedin, Instagram, Facebook, Globe } = Icons;

const { wrap, title, label, input, form, fullwidth, submit_button } = style;

export const SocialMediaForm = ({
  values,
  handleChange,
  handleSubmit,
  dirty,
  setDirty,
  touched,
  errors,
  isLoadingPatchUserSocialMedia,
}: SocialMediaFormProps) => {
  const OutletObject = useOutletContext<IOutletContext>();

  useEffect(() => {
    // mobile
    if (setDirty) {
      setDirty(dirty);
    }
    // desktop
    if (OutletObject?.dirtyCallback) {
      OutletObject.dirtyCallback(dirty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Honeycomb type="a" className={wrap}>
        <div className={title}>
          <Text size={[6, 6, 5]} font="inter" bold>
            {" "}
            Social media{" "}
          </Text>
        </div>
        {isLoadingPatchUserSocialMedia ? (
          <LoaderWrap />
        ) : (
          <div className={form}>
            <div className={input}>
              <div className={label}>
                <Twitter size="m" />
                <Text size="m">Twitter</Text>
              </div>
              <div className={fullwidth}>
                <Input
                  name="twitter"
                  value={values?.twitter || ""}
                  placeholder="Your username or profile URL"
                  onChange={handleChange}
                  error={touched.twitter && errors.twitter}
                />
              </div>
            </div>
            <hr className="solid" />
            <div className={input}>
              <div className={label}>
                <Linkedin size="m" />
                <Text size="m">LinkedIn</Text>
              </div>
              <div className={fullwidth}>
                <Input
                  name="linkedin"
                  value={values?.linkedin || ""}
                  placeholder="Your username or profile URL"
                  onChange={handleChange}
                  error={touched.linkedin && errors.linkedin}
                />
              </div>
            </div>
            <hr className="solid" />
            <div className={input}>
              <div className={label}>
                <Facebook size="m" />
                <Text size="m">Facebook</Text>
              </div>
              <div className={fullwidth}>
                <Input
                  name="facebook"
                  value={values?.facebook || ""}
                  placeholder="Your username or profile URL"
                  onChange={handleChange}
                  error={touched.facebook && errors.facebook}
                />
              </div>
            </div>
            <hr className="solid" />
            <div className={input}>
              <div className={label}>
                <Instagram size="m" />
                <Text size="m">Instagram</Text>
              </div>
              <div className={fullwidth}>
                <Input
                  name="instagram"
                  value={values?.instagram || ""}
                  placeholder="Your username or profile URL"
                  onChange={handleChange}
                  error={touched.instagram && errors.instagram}
                />
              </div>
            </div>
            <hr className="solid" />
            <div className={input}>
              <div className={label}>
                <Globe size="m" />
                <Text size="m">Website</Text>
              </div>
              <div className={fullwidth}>
                <Input
                  name="website"
                  value={values?.website || ""}
                  placeholder="Your website URL"
                  onChange={handleChange}
                  error={touched.website && errors.website}
                />
              </div>
            </div>
            <Button
              id="save-changes-button"
              size="m"
              theme="violet"
              type="solid"
              className={submit_button}
              disabled={isLoadingPatchUserSocialMedia}
              htmlType="submit"
            >
              Save changes
            </Button>
          </div>
        )}
      </Honeycomb>
    </form>
  );
};
