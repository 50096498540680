// FIX_ME:
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { Nullable } from "src/shared/utils";
import { messengerApi } from "../../api";
import { ChatMessageDTO } from "../../types";

type Args = {
  chatId: string;
  lastEvaluatedKey: Nullable<unknown>;
  newLastMessage: ChatMessageDTO;
  isMessageFromCurrentUser: boolean;
};

export const updteChatListOnReceiveNewMessage = ({
  chatId,
  lastEvaluatedKey,
  newLastMessage,
  isMessageFromCurrentUser,
}: Args) =>
  messengerApi.util.updateQueryData("getChatList", { lastEvaluatedKey }, (draft) => {
    const chatList = draft.data;

    const targetChat = chatList.find((chat) => chat.chat_id === chatId);

    if (!targetChat) throw new Error("TODO: обработать ошибку ");

    targetChat.last_message = newLastMessage;

    const sortedChatList = chatList.sort((a, b) => {
      if (!a.last_message?.datetime || !b.last_message?.datetime) return 0;

      const aLastMsgDate = a.last_message.datetime;
      const bLastMsgDate = b.last_message.datetime;

      return aLastMsgDate > bLastMsgDate ? -1 : 1;
    });

    // NOTE: необходимо отсортировать чат-лист в соответствии:
    //       самое недавнее из непрочитанных сообщений - сверху списка
    draft.data = sortedChatList;

    if (!isMessageFromCurrentUser) targetChat.unread_messages_count += 1;
  });
