import React, { FC } from "react";
import { Text } from "@evvve/ui-kit";
import { CurrentUser } from "src/store/User/types";
import s from "./style.module.scss";

type Props = {
  followingCount?: CurrentUser["following_count"];
  followersCount?: CurrentUser["followers_count"];
  shouldHide?: boolean;
};

const SubscriptionsInfo: FC<Props> = ({
  followingCount,
  followersCount,
  shouldHide,
}) => {
  const renderButton = (text: string, count?: string | number) => (
    <div className={s.info_text}>
      <Text as="h6" color="grayscale900" size="l">
        {count}
      </Text>
      <Text as="p" color="grayscale900" size="s">
        {text}
      </Text>
    </div>
  );

  return shouldHide ? null : (
    <div className={s.information}>
      {renderButton("Following", followingCount)}
      {renderButton("Followers", followersCount)}
    </div>
  );
};

export default SubscriptionsInfo;
