// FIX_ME:
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement } from "react";
import cn from "classnames";
import { createPortal } from "react-dom";
import { noop } from "lodash";
import s from "./ModalBack.module.scss";

interface ModalBackProps {
  className?: string;
  children: ReactElement;
  isOpen: boolean;
  onClose: () => void;
  withBackground?: boolean;
  asReactPortal?: boolean;
}

export const ModalBack = ({
  children,
  isOpen,
  onClose,
  withBackground = true,
  asReactPortal = true,
  className = "",
}: ModalBackProps) => {
  const modal = isOpen ? (
    <div className={cn(s.wrap, className)}>
      {withBackground && (
        <div
          className={s.background}
          onClick={onClose}
          onKeyDown={noop}
          role="button"
          tabIndex={0}
          aria-labelledby="TODO:"
        />
      )}
      {children}
    </div>
  ) : (
    <></>
  );

  // PROD-1894 bug fix: portal is rendered under fullscreen on Android 14
  return asReactPortal ? createPortal(modal, document.body) : modal;
};
