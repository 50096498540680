import React, { FC, PropsWithChildren } from "react";
import { Text } from "@evvve/ui-kit";
import { Placeholder } from "src/shared/ui";
import styles from "./styles.module.scss";

interface Props extends PropsWithChildren {
  isPlaceholderView: boolean;
}

const TitleBlock: FC<Props> = ({ children, isPlaceholderView }) => {
  if (isPlaceholderView) return <Placeholder width="80%" height="24px" />;

  return (
    <span title={typeof children === "string" ? children : undefined}>
      <Text size="m" color="grayscale1000" className={styles.title}>
        {children}
      </Text>
    </span>
  );
};

export default TitleBlock;
