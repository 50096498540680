import React from "react";
import s from "./style.module.scss";

const ProfilePlaceholder = () => (
  <div className={s.wrap}>
    <div className={s.bigMessage} />
    <div className={s.groupOfLittleMessages}>
      <div className={s.title} />
      <div className={[s.littleMessage, s.first].join(" ")} />
      <div className={[s.littleMessage, s.second].join(" ")} />
      <div className={[s.littleMessage, s.third].join(" ")} />
    </div>
    <div className={s.groupOfIcons}>
      <div className={s.icon} />
      <div className={s.icon} />
      <div className={s.icon} />
      <div className={s.icon} />
    </div>
    <div className={s.signature} />
  </div>
);

export default ProfilePlaceholder;
