import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventStatus, SessionResponse } from "../../store/events/types";
import {
  fitEventLiveViewMetricLssList,
  LiveHlsDataResponse,
  LiveStreamStatus,
} from "./types";
import { WssResponseData } from "./types.wss";

interface Slice {
  requiredEventStatus: EventStatus | null;
  prevLiveStreamStatus: LiveStreamStatus | null;
  liveStreamStatus: LiveStreamStatus | null;
  requiredLiveStreamStatus: LiveStreamStatus | null;
  isEventLiveViewMetricLssFit: boolean;
  liveData: LiveHlsDataResponse | null;
  isChangeEventStatusError: boolean;
  lastStreamingAction: WssResponseData | null;
  currentSessionData?: CurrentSessionData | null; // complex, org invited a speaker
  nextSession: SessionResponse | null;
  lastFinishedSessionId: string | null;
  isStreamingInterrupted: boolean | null;
  isAccessToSharedScreenRevoked: boolean | null;
}
export interface CurrentSessionData {
  sessionId: string;
  speakerId: string;
  isCurrentUserInLive?: boolean;

  // true: org set stream on pause
  // true: backend autopause when org is disconnected during live stream (leaves EventSpace, network issues)
  // false: backend autopause when non-org-speaker-in-live is disconnected (leaves EventSpace, network issues)
  isPauseFromOrg?: boolean;
}

const initialState: Slice = {
  requiredEventStatus: null,
  prevLiveStreamStatus: null,
  liveStreamStatus: null,
  requiredLiveStreamStatus: null,
  isEventLiveViewMetricLssFit: false,
  liveData: null,
  isChangeEventStatusError: false,
  lastStreamingAction: null,
  currentSessionData: null,
  nextSession: null,
  lastFinishedSessionId: null,
  isStreamingInterrupted: null,
  isAccessToSharedScreenRevoked: null,
};

const broadcastSlice = createSlice({
  name: "broadcast",
  initialState,
  reducers: {
    setLiveStreamStatus(
      state,
      { payload: liveStreamStatus }: PayloadAction<LiveStreamStatus | null>,
    ) {
      // console.log(liveStreamStatus, " --> null");
      state.liveStreamStatus = liveStreamStatus;
      if (liveStreamStatus && !/PRE_/.test(liveStreamStatus)) {
        state.prevLiveStreamStatus = state.liveStreamStatus; // stable status only
      }
      state.requiredLiveStreamStatus = null;
      // action/pause comes before the delayed action/speaker_in_live
      // if (!(liveStreamStatus === LiveStreamStatus.STREAM
      //     && state.requiredLiveStreamStatus === LiveStreamStatus.PAUSE)) {
      //   state.requiredLiveStreamStatus = null;
      // }
      state.isEventLiveViewMetricLssFit =
        !!liveStreamStatus &&
        fitEventLiveViewMetricLssList.includes(liveStreamStatus);
    },
    setRequiredLiveStreamStatus(
      state,
      { payload }: PayloadAction<LiveStreamStatus | null>,
    ) {
      // console.log(state.liveStreamStatus, " --> ", payload);
      state.requiredLiveStreamStatus = payload;
    },
    setLiveData(state, { payload }: PayloadAction<LiveHlsDataResponse | null>) {
      state.liveData = payload;
    },
    setIsEMPButtonError(state, { payload }: PayloadAction<boolean>) {
      state.isChangeEventStatusError = payload;
    },
    setRequiredEventStatus(state, { payload }: PayloadAction<EventStatus | null>) {
      state.requiredEventStatus = payload;
    },
    setLastStreamingAction(
      state,
      { payload }: PayloadAction<WssResponseData | null>,
    ) {
      state.lastStreamingAction = payload;
    },
    setCurrentSessionData(
      state,
      { payload }: PayloadAction<CurrentSessionData | null>,
    ) {
      // console.log("currentSessionData", payload);
      state.currentSessionData = payload;
    },
    setNextSession(state, { payload }: PayloadAction<SessionResponse | null>) {
      state.nextSession = payload;
    },
    setLastFinishedSessionId(state, { payload }: PayloadAction<string | null>) {
      state.lastFinishedSessionId = payload;
    },
    setIsStreamingInterrupted(state, { payload }: PayloadAction<boolean | null>) {
      state.isStreamingInterrupted = payload;
    },
    setIsAccessToSharedScreenRevoked(
      state,
      { payload }: PayloadAction<boolean | null>,
    ) {
      state.isAccessToSharedScreenRevoked = payload;
    },
  },
});

export const {
  setLiveStreamStatus,
  setLiveData,
  setIsEMPButtonError,
  setRequiredEventStatus,
  setLastStreamingAction,
  setRequiredLiveStreamStatus,
  setCurrentSessionData,
  setNextSession,
  setLastFinishedSessionId,
  setIsStreamingInterrupted,
  setIsAccessToSharedScreenRevoked,
} = broadcastSlice.actions;

export default broadcastSlice.reducer;
